import { put, takeEvery } from "redux-saga/effects";
import { fetchMethod } from "../../../../../services/httpService";
import messagePopup from "../../../../../utils/messagePopup";

// ========================================== Action Type =====================================================
export const actionType = {
  VIDEO_PHOTO_SAVE_START: "VIDEO_PHOTO_SAVE_START",
  VIDEO_PHOTO_SUCCESS: "VIDEO_PHOTO_SUCCESS",
  VIDEO_PHOTO_FAILED: "VIDEO_PHOTO_FAILED",
};

// ============================================ Actions ========================================================

export const saveVideoPhotoStart = (data) => ({
  type: actionType.VIDEO_PHOTO_SAVE_START,
  payload: data,
});

export const saveVideoPhotoSuccess = (data) => ({
  type: actionType.VIDEO_PHOTO_SUCCESS,
  payload: data,
});

export const saveVideoPhotoFailed = (data) => ({
  type: actionType.VIDEO_PHOTO_FAILED,
  payload: data,
});

// =========================================== Reducer ===========================================================
const INIT_STATE = {
  loading: false,
  data: null,
  error: null,
};

export default function saveVideoPhotoReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case actionType.VIDEO_PHOTO_SAVE_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.VIDEO_PHOTO_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case actionType.VIDEO_PHOTO_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}

// ===================================================== side effect =============================================

export function* saveVideoPhotoSaga(action) {
  try {
    const response = yield fetchMethod(
      `/api/Uploadedvideoandphotos/addPhotosAndVideos`, /// Will not work in staging or dev because api is through a different domain
      action.payload.variables,
      "post",
      true
    );
    if (response && response.status === 200) {
      action.payload.nextScreen();
      yield put(saveVideoPhotoSuccess({data: action.payload}));
    } else {
      messagePopup("", "Save Video Photo Failed", "error");
      yield put(saveVideoPhotoFailed("Save Video Photo Failed"));
    }
  } catch (error) {
    messagePopup("", "Save Video Photo Failed", "error");
    yield put(saveVideoPhotoFailed("Save Video Photo Failed"));
  }
}

export function* watchSaveVideoPhotoSaga() {
  yield takeEvery(actionType.VIDEO_PHOTO_SAVE_START, saveVideoPhotoSaga);
}
