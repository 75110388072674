import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import OwlCarousel from 'react-owl-carousel';
import closeGrayIcon from '../assets/img/close-gray-icon.svg';
import BackdropLoader from '../../../../../common/Loader';
import { savePrivatePublicStatusQuery } from './VideoPhotoQuery';
import { getUserInfo } from '../../../../../../services/authService';
import { TextField } from '@mui/material';
import DeleteVideoPhotoPopup from './DeleteVideoPhotoPopup';
import { VideoPop } from '../../../../landingPage/common/videoPopup';
import { getThumbnail } from '../../../../../common/thumbnail';
import { sendVideoPhotoNotification } from '../../../../../../utils/sendNotificationToAdvisor';
import InlineLoader from '../../../../../common/InlineLoader';

const ItemPhoto = ({
  handleModal,
  onSaveData,
  data = null,
  closeVideoPhotoLink,
  index,
  updateRecord,
  deletePhotoVideo,
  publicPrivateStatus,
  savePubliPrivateStatusStart,
  deleteVideoOrPhoto,
  isEdit,
  loading,
}) => {
  const [editInfo, setEditInfo] = useState(false);
  const [selectedAlbul, setSelectedAlbum] = useState(0);
  const [selectedTag, setSelectedTag] = useState(0);
  const [openPublicPrivateModal, setOpenPublicPrivateModal] = useState(false);
  const [title, setTitle] = useState(
    data.originalname ? data.originalname : ''
  );

  useEffect(() => {
    if (data.action === 'update') {
      setEditInfo(true);
      setSelectedAlbum(data.albumid ? data.albumid : 0);
      setSelectedTag(data.tagid ? data.tagid : 0);
      setTitle(data.originalname ? data.originalname : '');
    }
  }, [data]);

  const handleValueChange = (e) => {
    setTitle(e.target.value);
  };

  const onEditInfo = () => {
    setEditInfo(!editInfo);
  };
  const handleSavePhoto = () => {
    if (publicPrivateStatus.data && !publicPrivateStatus.data.isshowpopup) {
      onSaveData(
        [
          {
            ...data,
            albumId: +selectedAlbul ? selectedAlbul : null,
            tagId: +selectedTag ? selectedTag : null,
            isvisible: +publicPrivateStatus.data.popupvalue ? 1 : 0,
            title: title,
          },
        ],
        index
      );
    } else {
      setOpenPublicPrivateModal(true);
    }
  };
  const handlePublicPrivateModal = (publiPrivateValue, doNotAskValue) => {
    setOpenPublicPrivateModal(false);
    onSaveData(
      [
        {
          ...data,
          albumId: +selectedAlbul ? selectedAlbul : null,
          tagId: +selectedTag ? selectedTag : null,
          isvisible: publiPrivateValue === 'yes' ? 1 : 0,
          title: title,
        },
      ],
      index
    );
    savePubliPrivateStatusStart({
      query: savePrivatePublicStatusQuery,
      variables: {
        obj: {
          isshowpopup: doNotAskValue ? 0 : 1,
          popupvalue: publiPrivateValue === 'yes' ? '1' : '0',
          userid: getUserInfo ? getUserInfo().id : 0,
          id: publicPrivateStatus.data
            ? publicPrivateStatus.data.id
              ? publicPrivateStatus.data.id
              : 0
            : 0,
        },
      },
    });
    sendVideoPhotoNotification();
  };
  return (
    <div className="item">
      <div className="video-detail-box">
        <div className="img-box">
          <img src={data?.name} alt="profileBg" />
        </div>
        <div className="detail-box">
          <div className="video-share-box">
            <span
              className={'btn share-btn'}
              style={{ background: 'none' }}
            ></span>
            {loading && (
              <button className="close" onClick={closeVideoPhotoLink}>
                <img src={closeGrayIcon} alt="" />
              </button>
            )}
          </div>
          <div className="profile-info-box">
            <div className="top-block">
              <span>Photo</span>
              <span className="edit-button-toggle" onClick={onEditInfo}>
                Edit
              </span>
            </div>
            <div
              className="profile-content"
              style={{ display: editInfo ? 'block' : 'none' }}
            >
              <div className="form-group">
                <TextField
                  placeholder="Add title"
                  style={{ width: '100%' }}
                  name="title"
                  value={title || ''}
                  onChange={handleValueChange}
                />
              </div>
            </div>
            <div className="button-box-info">
              {loading ? (
                <InlineLoader open={loading} />
              ) : (
                <>
                  {isEdit && (
                    <button
                      type="button"
                      className="btn delete-button"
                      onClick={() => {
                        if (data.action === 'update') {
                          deletePhotoVideo(data);
                        } else {
                          deleteVideoOrPhoto(index);
                        }
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="9.677"
                        height="11.91"
                        viewBox="0 0 9.677 11.91"
                      >
                        <g transform="translate(-48)">
                          <path
                            className="a"
                            d="M56.746,1.489H54.7V1.117A1.117,1.117,0,0,0,53.583,0H52.094a1.117,1.117,0,0,0-1.117,1.117v.372H48.93a.93.93,0,0,0-.93.93v.744a.372.372,0,0,0,.372.372H57.3a.372.372,0,0,0,.372-.372V2.419A.93.93,0,0,0,56.746,1.489Zm-5.024-.372a.373.373,0,0,1,.372-.372h1.489a.373.373,0,0,1,.372.372v.372H51.722Z"
                          ></path>
                          <path
                            className="a"
                            d="M73.48,184a.116.116,0,0,0-.116.122l.307,6.444a1.115,1.115,0,0,0,1.115,1.064h5.652a1.115,1.115,0,0,0,1.115-1.064l.307-6.444a.116.116,0,0,0-.116-.122Zm5.621.93a.372.372,0,0,1,.744,0v4.838a.372.372,0,1,1-.744,0Zm-1.861,0a.372.372,0,0,1,.744,0v4.838a.372.372,0,1,1-.744,0Zm-1.861,0a.372.372,0,0,1,.744,0v4.838a.372.372,0,1,1-.744,0Z"
                            transform="translate(-24.774 -179.72)"
                          ></path>
                        </g>
                      </svg>
                      Delete
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn green"
                    onClick={() => {
                      if (data.action === 'update') {
                        updateRecord({
                          ...data,
                          albumid: +selectedAlbul ? selectedAlbul : null,
                          tagid: +selectedTag ? selectedTag : null,
                          title: title,
                        });
                      } else {
                        handleSavePhoto();
                      }
                    }}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn"
                    onClick={closeVideoPhotoLink}
                  >
                    Cancel
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={openPublicPrivateModal}
        onClose={(event, reason) => {
          if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
            setOpenPublicPrivateModal(false);
          }
        }}
        className="matDialogCustom"
      >
        <ConfirmPublicPrivateModal
          onClose={() => {
            setOpenPublicPrivateModal(false);
          }}
          handlePublicPrivateModal={handlePublicPrivateModal}
        />
      </Dialog>
    </div>
  );
};
const ItemVideo = ({
  handleModal,
  onSaveData,
  deletePhotoVideo,
  data,
  closeVideoPhotoLink,
  index,
  onSaveDataLink,
  updateRecord,
  publicPrivateStatus,
  savePubliPrivateStatusStart,
  deleteVideoOrPhoto,
  isEdit,
  loading,
}) => {
  const [editInfo, setEditInfo] = useState(false);
  const [position, setposition] = useState('');
  const [jerseyno, setjerseyno] = useState('');
  const [jerseycolor, setjerseycolor] = useState('');
  const [title, setTitle] = useState(
    data.originalname ? data.originalname : ''
  );
  const [openPublicPrivateModal, setOpenPublicPrivateModal] = useState(false);
  const [vidID, setVidID] = useState(null);
  const [isVidPop, setIsVidPop] = useState(false);
  const [isLink, setIsLink] = useState(false);

  useEffect(() => {
    if (data.action === 'update') {
      setEditInfo(true);
      setposition(data.position ? data.position : '');
      setjerseyno(data.jerseyno ? data.jerseyno : '');
      setjerseycolor(data.jerseycolor ? data.jerseycolor : '');
      setTitle(data.originalname);
    }
  }, [data]);
  const handleValueChange = (e) => {
    setTitle(e.target.value);
  };
  const onEditInfo = () => {
    setEditInfo(!editInfo);
  };
  const openVideoOnNewTab = (url, linkType) => {
    if (url) {
      // window.open(url, "_blank");
      setVidID(url);
      setIsLink(linkType);
      setTimeout(() => {
        setIsVidPop(true);
      }, 500);
    }
  };
  const handleSavePhoto = () => {
    if (publicPrivateStatus.data && !publicPrivateStatus.data.isshowpopup) {
      if (data.type === 1) {
        onSaveData(
          [
            {
              ...data,
              jerseycolor,
              jerseyno,
              position,
              isvisible: +publicPrivateStatus.data.popupvalue ? 1 : 0,
              title: title,
            },
          ],
          index
        );
      } else {
        onSaveDataLink(
          {
            ...data,
            jerseycolor,
            jerseyno,
            position,
            isvisible: +publicPrivateStatus.data.popupvalue ? 1 : 0,
            title: title,
          },
          index
        );
      }
    } else {
      setOpenPublicPrivateModal(true);
    }
    sendVideoPhotoNotification();
  };
  const handlePublicPrivateModal = (publiPrivateValue, doNotAskValue) => {
    setOpenPublicPrivateModal(false);
    if (data.type === 1) {
      onSaveData(
        [
          {
            ...data,
            jerseycolor,
            jerseyno,
            position,
            isvisible: publiPrivateValue === 'yes' ? 1 : 0,
            title: title,
          },
        ],
        index
      );
    } else {
      console.log("HERE")
      onSaveDataLink(
        {
          ...data,
          jerseycolor,
          jerseyno,
          position,
          isvisible: publiPrivateValue === 'yes' ? 1 : 0,
          title: title,
        },
        index
      );
    }
    savePubliPrivateStatusStart({
      query: savePrivatePublicStatusQuery,
      variables: {
        obj: {
          isshowpopup: doNotAskValue ? 0 : 1,
          popupvalue: publiPrivateValue === 'yes' ? '1' : '0',
          userid: getUserInfo ? getUserInfo().id : 0,
          id: publicPrivateStatus.data
            ? publicPrivateStatus.data.id
              ? publicPrivateStatus.data.id
              : 0
            : 0,
        },
      },
    });
  };
  return (
    <>
      <div className="item">
        <div className="video-detail-box">
          <div className="img-box">
            {data.type === 1 ? (
              <>
                <video playsInline>
                  <source src={data.name} alt="video" />
                </video>
                <div
                  className="video-play-button"
                  onClick={() => openVideoOnNewTab(data.name, false)}
                >
                  <div className="video-play-inner">
                    <button>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="14"
                        viewBox="0 0 8 14"
                      >
                        <path
                          className="a"
                          d="M8.1,0l8.1,10.8H0Z"
                          transform="translate(8) rotate(90)"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <video
                  poster={getThumbnail(data.name, data, 'Vid/Photo')}
                  // controls
                >
                  <source src={data.name} alt="video/youtube" />
                </video>
                <div
                  className="video-play-button"
                  onClick={() => openVideoOnNewTab(data.name, true)}
                >
                  <div className="video-play-inner">
                    <button>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="14"
                        viewBox="0 0 8 14"
                      >
                        <path
                          className="a"
                          d="M8.1,0l8.1,10.8H0Z"
                          transform="translate(8) rotate(90)"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="detail-box">
            <div className="video-share-box">
              <span
                className={'btn share-btn'}
                style={{ background: 'none' }}
              ></span>

              {!loading && (
                <button className="close" onClick={closeVideoPhotoLink}>
                  <img src={closeGrayIcon} alt="" />
                </button>
              )}
            </div>
            <div className="profile-info-box">
              <div className="top-block">
                <span>Highlights Video</span>
                <span
                  className="edit-button-toggle"
                  id="video-metadata-edit-toggle"
                  onClick={onEditInfo}
                >
                  Edit
                </span>
              </div>
              <div
                className="profile-content"
                style={{ display: editInfo ? 'block' : 'none' }}
              >
                <div className="form-group">
                  <TextField
                    placeholder="Add title"
                    style={{ width: '100%' }}
                    name="title"
                    value={title || ''}
                    id="video-metadata-title-field"
                    onChange={handleValueChange}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="position"
                    id="position1"
                    placeholder="Position"
                    value={position}
                    onChange={(e) => setposition(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="gersey"
                    id="gersey1"
                    placeholder="Jersey #"
                    value={jerseyno}
                    onChange={(e) => setjerseyno(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="gerseycolor"
                    id="gerseycolor1"
                    placeholder="Jersey Color"
                    value={jerseycolor}
                    onChange={(e) => setjerseycolor(e.target.value)}
                  />
                </div>
              </div>
              <div className="button-box-info">
                {loading ? (
                  <>
                    <h3>
                      Your video is uploading, and will be processed to ensure
                      maximum device compatibility. You'll receive an email when
                      the video is live!
                    </h3>
                    <InlineLoader open={loading} />
                  </>
                ) : (
                  <>
                    {isEdit && (
                      <button
                        type="button"
                        className="btn delete-button"
                        onClick={() => {
                          if (data.action === 'update') {
                            deletePhotoVideo(data);
                          } else {
                            deleteVideoOrPhoto(index);
                          }
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="9.677"
                          height="11.91"
                          viewBox="0 0 9.677 11.91"
                        >
                          <g transform="translate(-48)">
                            <path
                              className="a"
                              d="M56.746,1.489H54.7V1.117A1.117,1.117,0,0,0,53.583,0H52.094a1.117,1.117,0,0,0-1.117,1.117v.372H48.93a.93.93,0,0,0-.93.93v.744a.372.372,0,0,0,.372.372H57.3a.372.372,0,0,0,.372-.372V2.419A.93.93,0,0,0,56.746,1.489Zm-5.024-.372a.373.373,0,0,1,.372-.372h1.489a.373.373,0,0,1,.372.372v.372H51.722Z"
                            ></path>
                            <path
                              className="a"
                              d="M73.48,184a.116.116,0,0,0-.116.122l.307,6.444a1.115,1.115,0,0,0,1.115,1.064h5.652a1.115,1.115,0,0,0,1.115-1.064l.307-6.444a.116.116,0,0,0-.116-.122Zm5.621.93a.372.372,0,0,1,.744,0v4.838a.372.372,0,1,1-.744,0Zm-1.861,0a.372.372,0,0,1,.744,0v4.838a.372.372,0,1,1-.744,0Zm-1.861,0a.372.372,0,0,1,.744,0v4.838a.372.372,0,1,1-.744,0Z"
                              transform="translate(-24.774 -179.72)"
                            ></path>
                          </g>
                        </svg>
                        Delete
                      </button>
                    )}
                    {data.type === 1 ? (
                      <button
                        type="button"
                        className="btn green"
                        onClick={() => {
                          if (data.action === 'update') {
                            updateRecord({
                              ...data,
                              jerseycolor: jerseycolor,
                              jerseyno: jerseyno,
                              position: position,
                              title: title,
                            });
                          } else {
                            handleSavePhoto();
                          }
                        }}
                      >
                        Save
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn green"
                        id="video-url-save-button"
                        onClick={() => {
                          if (data.action === 'update') {
                            updateRecord({
                              ...data,
                              jerseycolor: jerseycolor,
                              jerseyno: jerseyno,
                              position: position,
                              title: title,
                            });
                          } else {
                            handleSavePhoto();
                          }
                        }}
                      >
                        Save
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn"
                      onClick={closeVideoPhotoLink}
                    >
                      Cancel
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <Dialog
          open={openPublicPrivateModal}
          onClose={(event, reason) => {
            if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
              setOpenPublicPrivateModal(false);
            }
          }}
          className="matDialogCustom"
        >
          <ConfirmPublicPrivateModal
            onClose={() => {
              setOpenPublicPrivateModal(false);
            }}
            handlePublicPrivateModal={handlePublicPrivateModal}
          />
        </Dialog>
      </div>
      <VideoPop
        vidID={vidID}
        isTestSingle={isVidPop}
        setIsTestSingle={() => setIsVidPop(false)}
        isToken={1}
        isLink={isLink}
      />
    </>
  );
};

const ConfirmPublicPrivateModal = (props) => {
  const [doNotAsk, setDoNotAsk] = useState(false);
  const [isClick, setIsClick] = useState(false);
  const handleChange = (e) => {
    setDoNotAsk(e.target.checked);
  };
  const handleYesNoClick = (flag) => {
    setIsClick(true);
    // button type value, doNotAskValue
    props.handlePublicPrivateModal(flag, doNotAsk);
  };
  useEffect(() => {
    setIsClick(false);
  }, []);
  return (
    <div
      className="modal fade common-modal trash-modal stats-video-modal show"
      id="StatsVideoTrashModal"
      aria-modal="true"
      role="dialog"
      style={{ paddingRight: '9px', display: 'block' }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={props.onClose}
              style={{ top: '10px' }}
            >
              <img src={closeGrayIcon} alt="" />
            </button>

            <div className="content-box">
              <h4>Are you sure you want to show video / photo to public?</h4>
            </div>
            <div className="content-box">
              <input
                type="checkbox"
                id="doNotAsk"
                name="doNotAsk"
                checked={doNotAsk}
                onChange={handleChange}
              />{' '}
              <label
                className="ml-1"
                htmlFor="doNotAsk"
                style={{ display: 'inline-block', verticalAlign: 'top' }}
              >
                {' '}
                Don’t ask me again
              </label>
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn"
              disabled={isClick}
              style={{ backgroundColor: '#1CB79E', color: 'fff !important' }}
              onClick={() => handleYesNoClick('yes')}
            >
              Yes
            </button>
            <button
              type="button"
              className="btn"
              id="video-save-not-public-button"
              disabled={isClick}
              onClick={() => handleYesNoClick('no')}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const EditVideoPhotoOverlay = ({
  open,
  onSaveData,
  handleModal,
  onVideoOpen,
  data = null,
  closeVideoPhotoLink,
  deleteTemporaryData,
  allAlbum = null,
  allTags = null,
  loading = false,
  onSaveDataLink,
  updateRecord,
  deletePhotoVideo,
  publicPrivateStatus,
  savePubliPrivateStatusStart,
  isEdit,
}) => {
  const [newData, setnewData] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [deletedIndex, setDeletedIndex] = useState(0);
  useEffect(() => {
    if (data) {
      setnewData([...data]);
    }
  }, [data]);

  const deleteVideoOrPhoto = (index) => {
    setDeletedIndex(index);
    setShowDeleteModal(true);
  };
  const deleteVideoAndPhoto = () => {
    setShowDeleteModal(false);
    deleteTemporaryData(deletedIndex);
  };
  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={
          loading
            ? null
            : (event, reason) => {
                if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
                  closeVideoPhotoLink();
                }
              }
        }
        className="matDialogCustom matDialogVideoPhotos"
        disableBackdropClick={loading}
        disableEscapeKeyDown={loading}
      >
        <div
          className="modal fade common-slide-modal show modalActive"
          style={{ display: open ? 'block' : 'none' }}
          id="videoPhotosModal"
        >
          <BackdropLoader open={loading} />
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body modal-videophoto-slider">
                {newData && newData.length != 0 ? (
                  <OwlCarousel
                    className="owl-theme"
                    key={newData.length}
                    loop={false}
                    margin={1}
                    items="1"
                    nav
                  >
                    {newData.map((item, index) => {
                      if (item.type === 0) {
                        return (
                          <ItemPhoto
                            key={index}
                            onSaveData={onSaveData}
                            handleModal={handleModal}
                            data={item}
                            closeVideoPhotoLink={closeVideoPhotoLink}
                            index={index}
                            allAlbum={allAlbum}
                            allTags={allTags}
                            updateRecord={updateRecord}
                            deletePhotoVideo={deletePhotoVideo}
                            publicPrivateStatus={publicPrivateStatus}
                            savePubliPrivateStatusStart={
                              savePubliPrivateStatusStart
                            }
                            deleteVideoOrPhoto={deleteVideoOrPhoto}
                            isEdit={isEdit}
                            loading={loading}
                          />
                        );
                      } else if (item.type === 1 || item.type === 2) {
                        return (
                          <ItemVideo
                            key={index}
                            onSaveData={onSaveData}
                            handleModal={handleModal}
                            onVideoOpen={onVideoOpen}
                            data={item}
                            closeVideoPhotoLink={closeVideoPhotoLink}
                            index={index}
                            onSaveDataLink={onSaveDataLink}
                            updateRecord={updateRecord}
                            deletePhotoVideo={deletePhotoVideo}
                            publicPrivateStatus={publicPrivateStatus}
                            savePubliPrivateStatusStart={
                              savePubliPrivateStatusStart
                            }
                            deleteVideoOrPhoto={deleteVideoOrPhoto}
                            isEdit={isEdit}
                            loading={loading}
                          />
                        );
                      } else {
                        return null;
                      }
                    })}
                  </OwlCarousel>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={showDeleteModal}
        onClose={(event, reason) => {
          if (reason == 'backdropClick' || reason == 'escapeKeyDown') {
            closeDeleteModal();
          }
        }}
      >
        <DeleteVideoPhotoPopup
          onDeleteClick={deleteVideoAndPhoto}
          onClose={closeDeleteModal}
        />
      </Dialog>
    </>
  );
};

export { EditVideoPhotoOverlay };
