import SubHeader from "../SubHeader";
import LeftSideEditProfile from "../LeftSideEditProfile";
import React, {useEffect, useRef, useState} from "react";
import {fetchGraphMethod, fetchGraphMethodNGROX, fetchMethod} from "../../../../../../services/httpService";
import {
    allStatsEventType, allStatsMatCardData,
    allStatsReportMat,
    allStatsTechCompData,
    fetchAllMetricsWithDetailsQuery, getAllJumpData,
    reportFetchMat, saveStatmatrixdetails, saveStatsDetailCreateReport, saveStatsDetailReport
} from "./statsQuery";
import {getAuthToken, getUserInfo} from "../../../../../../services/authService";
import MuiAutoComplete from "../../../../../common/AutoComplete";
import {getFilAllOrganizationsQuery} from "../../../../../common/CommonQuery";
import moment from 'moment'
import { Dialog } from "@mui/material";
import {ReportPop} from "./Components";
import BackdropLoader from "../../../../../common/Loader";
import toastr from "toastr";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {getvitalDataQuery} from "../vitalQuery";
import ScreenSwitch from "../ScreenSwitch";
import {useHistory} from 'react-router-dom'
export const StatsReports = () =>{
    const history = useHistory()
    const fakeInput = useRef(null);
    const [sideMenuOpen, setSideMenu] = useState(false);
    const [metListData, setMetListData] = useState([])
    const [eventTypeArr, setEventTypeArr] = useState([])
    const [allOrg, setAllOrg] = useState([])
    const [techCompArr, setTechCompArr] = useState([])
    const [matCardArr, setMatCardArr] = useState([])
    const [heightInFeetList, setHeightInFeetList] = useState([])
    const [heightInInchList, setHeightInInchList] = useState([])
    const [statReportField, setStatReportField] = useState({date: moment(new Date()).format('YYYY-MM-DD')})
    const [isSuccess, setIsSuccess] = useState(false)
    const [eventORG ,setEventORG] = useState(false)
    const [isTechOpen, setIsTechOpen] = useState(false)
    const [error, setError] = useState({})
    const [matError, setMatError] = useState([])
    const [isPop, setIsPop] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [fields, setFields] = useState([])
    const [isImage, setIsImage] = useState(false)
    const [videoData, setVideoData] = useState(null);
    const [videoOpen, setVideoOpen] = useState(false);
    const [vitalData, setVitalData] = useState({})
    const onSideMenuClick = () => {
        setSideMenu(!sideMenuOpen);
    };
    useEffect(()=>{
        getMetricList()
        getAthVital()
        setTimeout(()=>{
            window.scrollTo(0,0)
        },500)
    },[])
    const getAthVital = async () =>{
        const _res = await fetchGraphMethod(getvitalDataQuery, {obj: {userid: getUserInfo().id,}}, true)
        if (_res?.status == 200){
            const vit = _res?.data?.data?.allOnboardings?.Onboardings
            setVitalData(vit)
        }else{
            setVitalData({})
        }
    }
    const getAllJumpDataList = async () =>{
        const _res = await fetchGraphMethod(getAllJumpData, null, true)
        if (_res?.status == 200){
            const dataFt = _res?.data?.data?.allFeet?.Feet?.length > 0 ?
                _res?.data?.data?.allFeet?.Feet : []
            const dataIn = _res?.data?.data?.allInches?.Inches?.length > 0 ?
                _res?.data?.data?.allInches?.Inches : []
            setHeightInFeetList(dataFt)
            setHeightInInchList(dataIn)
        }else{
            setHeightInFeetList([])
            setHeightInInchList([])
        }
    }
    const getMetricList = async () =>{
        setIsLoading(true)
        const _res = await fetchGraphMethod(allStatsReportMat, {
            where:{
                isreportstats: 1,
                active: 1,
                order: "priority  ASC",
            }
        }, !!getAuthToken() ? true : false)
        if (_res?.status == 200){
            const matData = _res?.data?.data?.allStatmatrices?.Statmatrices?.length > 0 ?
                _res?.data?.data?.allStatmatrices?.Statmatrices : []
                setMetListData(matData)
            setIsLoading(false)
            }else{
            setMetListData([])
            setIsLoading(false)
        }
    }
    const getStatsEventType = async (id) =>{
        const _res = await fetchGraphMethod(allStatsEventType, {
        where:{statsid: +id, order: "priority  ASC",}}, true)
        if (_res?.status == 200){
            const data = _res?.data?.data?.allStatseventtypemappings?.Statseventtypemappings?.length > 0 ?
                _res?.data?.data?.allStatseventtypemappings?.Statseventtypemappings : []
                setEventTypeArr(data)
            setIsLoading(false)
        }else{
            setEventTypeArr([])
            setIsLoading(false)
        }
    }
    const getTechComp = async (id) =>{
        const _res = await fetchGraphMethod(allStatsTechCompData,{
        where : {statsid:+id, order: "priority  ASC",}
        }, true)
        if (_res?.status == 200){
            const techData = _res?.data?.data?.allCompanystatsmappings?.Companystatsmappings?.length > 0 ?
                _res?.data?.data?.allCompanystatsmappings?.Companystatsmappings : []
            setTechCompArr(techData)
            setIsLoading(false)
        }else{
            setTechCompArr([])
            setIsLoading(false)
        }
    }
    const handleForEventOrg = async (e, field) =>{
        const value = !!e?.target?.value ? e?.target?.value : ""
       let  cloneStats = statReportField
        if (!!value && value?.length > 2){
            setIsLoading(true)
            const orgInput = {where: {active: 1,isverified: 1, isreportstats: 1, and: [{or: [{eventorgname: {like: `%${value}%`, options: "i",},},],},],},}
            const _res = await fetchGraphMethod(getFilAllOrganizationsQuery, orgInput, true)
            if (_res?.status == 200){
                const allOrgData = _res?.data?.data?.allOrganizations?.Organizations?.length > 0 ? _res?.data?.data?.allOrganizations?.Organizations : []
                setAllOrg(allOrgData)
                setIsLoading(false)
                setError({})
            }else{
                setAllOrg([])
                setIsLoading(false)
                setError({})
            }
        }else{
            cloneStats[field] = ""
            setAllOrg([])
            setIsLoading(false)
            setFields([])
            setMatCardArr([])
            setStatReportField({...cloneStats})
            setError({})
        }
    }
    const getMatCardsData = async (id) =>{
        const _res = await fetchGraphMethod(allStatsMatCardData,{
            where: {statsmatrixid: +id ,isreportstats: 1, order: "priority  ASC",}
        }, true)
        if (_res?.status == 200){
            const mtCard = _res?.data?.data?.allStatmatrixmappings?.Statmatrixmappings?.length > 0 ?
                _res?.data?.data?.allStatmatrixmappings?.Statmatrixmappings : []
            setMatCardArr(mtCard)
            const fieldArr = [],
                  valTotal = []
            mtCard?.map(el=>valTotal?.push(el?.id))
            const _resTotal = await fetchMethod('/api/Statmatrixdetails/statMatrixGroupByCount',
                {userId: getUserInfo()?.id,statMatrixIds: valTotal},
                'POST', !!getAuthToken() ? true : false)
            let matLimit = _resTotal?.data?.response?.length > 0 ? _resTotal?.data?.response : []
            if (_resTotal?.status == 200){
                mtCard?.length > 0 && mtCard?.map((ph, idx) =>{
                    if (matLimit?.length > 0){
                      return   matLimit?.map((vlTl, vlIdx) =>{
                            if (ph?.id == vlTl?.id){
                                fieldArr?.push(
                                    {
                                        result:"", id: ph?.id , statsmatrixid: ph?.statsmatrixid, jumbidinfeet : "", jumbidininches: "",
                                        jumpincentimeter: "", jumpValueFt : "",jumpValueIn : "", lbs: "", reps: "",
                                        heightinfeetid: !!vitalData?.[0]?.heightinfeet ? vitalData?.[0]?.heightinfeet : null,
                                        heightininchecsid : !!vitalData?.[0]?.heightininches ? vitalData?.[0]?.heightininches : null,
                                        weight : !!vitalData?.[0]?.heightininches ? vitalData?.[0]?.weight : null,
                                        totalMat: vlTl?.totalCount ?? 0
                                    }
                                )
                            }
                        })
                    }else{
                        fieldArr?.push(
                            {
                                result:"", id: ph?.id , statsmatrixid: ph?.statsmatrixid, jumbidinfeet : "", jumbidininches: "",
                                jumpincentimeter: "", jumpValueFt : "",jumpValueIn : "", lbs: "", reps: "",
                                heightinfeetid: !!vitalData?.[0]?.heightinfeet ? vitalData?.[0]?.heightinfeet : null,
                                heightininchecsid : !!vitalData?.[0]?.heightininches ? vitalData?.[0]?.heightininches : null,
                                weight : !!vitalData?.[0]?.heightininches ? vitalData?.[0]?.weight : null,
                                totalMat: 0
                            }
                        )
                    }
                })
            }
            setFields(fieldArr)
            setIsLoading(false)
        }else{
            setIsLoading(false)
            setMatCardArr([])
        }
    }
    const onHandleChange = async (e, field) =>{
        let name = e?.target?.name,
            value = e?.target?.value,
            cloneStats = statReportField
        if (name == "statsType"){
            setMatCardArr([])
            setIsLoading(true)
            cloneStats[name] = !!value ? +value : value
            cloneStats['eventType'] = null
            cloneStats['techComp'] = null
            cloneStats['organizationname'] = null
           await getStatsEventType(+value)
            setIsTechOpen(false)
            setEventORG(false)
            setFields([])
            setMatCardArr([])
            if (value == 2){
                getAllJumpDataList()
                // getAllHeightInch()
            }
        }
        else if (name == "eventType"){
            cloneStats[name] = !!value ? +value : value
            cloneStats['techComp'] = null
            cloneStats['organizationname'] = null
            const eventData = eventTypeArr?.filter(fl =>fl?.eventtypeid == +value)
            if (eventData?.[0]?.isshoworg == 1 && (+value != 11 && (statReportField?.statsType != 2 && statReportField?.statsType != 3))){
                setEventORG(true)
                setIsTechOpen(false)
                setFields([])
                setMatCardArr([])
            }
           else if (eventData?.[0]?.isshoworg == 0 && (+value != 11 && (statReportField?.statsType != 2 && statReportField?.statsType != 3))){
                setMatCardArr([])
                setIsLoading(true)
                setEventORG(false)
               await getTechComp(cloneStats?.statsType ?? 'ER')
                setIsTechOpen(true)
                setFields([])
                setMatCardArr([])
            }
           else if ((statReportField?.statsType == 2 || statReportField?.statsType == 3) &&  (+value == 11 || +value == 2)){
                setMatCardArr([])
                setIsLoading(true)
                cloneStats[name] = !!value ? +value : value
                cloneStats['techComp'] = null
                cloneStats['organizationname'] = null
                await getMatCardsData(statReportField?.statsType ?? "ER")
                setEventORG(false)
                setIsTechOpen(false)
            }else {
                setEventORG(false)
                setIsTechOpen(false)
                setFields([])
                setMatCardArr([])
            }
        }
        else if (name == "techComp"){
            setMatCardArr([])
            setIsLoading(true)
            const techData = techCompArr?.filter(fl => fl?.companystatsmappingtechcompanyidmaprel?.Stattechcompanies?.[0]?.id == +value)
            cloneStats[name] = parseInt(+value)
           await getMatCardsData(techData?.[0]?.substatsid ?? "ER")
        }
        else if (field == "statsVidPhoto"){
            const file = e?.target?.files?.[0] ?? null
            if (!!file){
                if (file.type?.includes('video')){
                    const videoStatus = vidPhotoVal(e)
                    if (videoStatus){
                        cloneStats[field] = e?.target?.files[0] ?? ""
                        setIsImage(false)
                    }
                }else{
                    cloneStats[field] = e?.target?.files[0] ?? ""
                    setIsImage(true)
                }
            }else{
                cloneStats[field] = null
            }
        }
        else if (name == "date"){
            if (!!value){
                const curDate = moment(new Date()),
                    selDate = moment(e?.target?.value),
                    checkYr = curDate?.diff(selDate, 'years'),
                    checkMn = curDate?.diff(selDate, 'months'),
                    checkDy = curDate?.diff(selDate, 'days')
                if (checkDy <= 0 || checkMn < 0 || checkYr < 0) return
                cloneStats[name] = e?.target?.value
            }else{
                cloneStats[name] = moment(new Date())?.format("YYYY-MM-DD")
            }
        }
        else{
            cloneStats[name] = value
        }
        setMatError({})
        setError({})
        setStatReportField({...cloneStats})
    }
    const onSelectEventOrg = async (e, field) =>{
        if (!!e?.target?.value){
            setMatCardArr([])
            const value = !!e?.target?.value ? e?.target?.value?.eventorgname : e?.target?.value
            let cloneStats = statReportField
            cloneStats[field] = value
            setStatReportField({...cloneStats})
            setError({})
            await getMatCardsData(statReportField?.statsType ?? "ER")
        }
    }
    const vidPhotoVal = (e) =>{
        const file = e.target.files[0];
        const fileArr = file.name.split(".");
        fileArr[0] = new Date().getTime();
        //////////////
        ///below code is check over video format and uploading size
        let fileExtension = file.name
            .split(".")
            [file.name.split(".").length - 1]?.toLowerCase();
        if (file.type.includes("video")) {
            if (fileExtension != "mp4" &&
                fileExtension != "3g2" &&
                fileExtension != "3gp" &&
                fileExtension != "avi" &&
                fileExtension != "flv" &&
                fileExtension != "m4v" &&
                fileExtension != "mkv" &&
                fileExtension != "mov" &&
                fileExtension != "mpg" &&
                fileExtension != "mts" &&
                fileExtension != "m2ts" &&
                fileExtension != "mpeg" &&
                fileExtension != "wmv") {
                toastr.error("Video format must be 3g2, 3gp, avi, flv, m4v, mkv, mov, mp4, mpg, mts, m2ts, mpeg, wmv", "", {
                    progressBar: true, timeOut: 2000,
                    closeButton: true, positionClass: "toast-top-full-width",
                    showEasing: "swing", fadeIn: 40000,
                    preventDuplicates: true,
                })
                return false
            } else {
                let fileSize = parseInt(e?.target?.files?.[0]?.size) / (1024 * 1024);
                if ((parseFloat(fileSize).toFixed(3)) > 150) {//video limit 150 MB(change on 3 Jan)
                    toastr.error("Max Limit 150MB", "", {
                        progressBar: true, timeOut: 2000,
                        closeButton: true, positionClass: "toast-top-full-width",
                        showEasing: "swing", fadeIn: 40000,
                        preventDuplicates: true,
                    })
                    return false
                }else{
                    return true
                }
            }
        }
    }
    const onHandleMatCard = (e, data, idx) =>{
        let cloneField = JSON?.parse(JSON?.stringify(fields))
        if (statReportField?.statsType == 2){
            const name = e?.target?.name,
                value = e?.target?.value;
            if (!!value){
                cloneField[idx][name] = !!value ? +value : value
                const filVal = name == "jumbidinfeet" ?  heightInFeetList?.filter(fl =>fl?.id == +value) :
                    heightInInchList?.filter(fl =>fl?.id == +value)
                cloneField[idx][name == "jumbidinfeet" ?  'jumpValueFt' : "jumpValueIn"] = name == "jumbidinfeet" ?
                    filVal[0]['feetvalue'] : filVal[0]['inchvalue']
                cloneField[idx]['jumpincentimeter'] = name == "jumbidinfeet" ?
                    (parseFloat(filVal[0]['feetvalue'])* 30.48 +
                        parseFloat(!!cloneField?.[idx]?.['jumpValueIn'] ? cloneField?.[idx]?.['jumpValueIn'] : 0)* 2.54) :
                    (parseFloat(!!cloneField?.[idx]?.['jumpValueFt'] ? cloneField?.[idx]?.['jumpValueFt'] : 0)* 30.48 +
                        parseFloat(filVal[0]['inchvalue'])* 2.54)
                cloneField[idx]['result'] = cloneField[idx]['jumpincentimeter']
            }else{
                if (name == "jumbidinfeet" ? !cloneField[idx]?.jumbidininches : !cloneField[idx]?.jumbidinfeet){
                    cloneField[idx][name] = value
                    cloneField[idx]['result'] = value
                }else{
                    cloneField[idx][name] = value
                }
            }
        }
        else if (statReportField?.statsType == 3){
            const name = e?.target?.name,
                value = e?.target?.value;
            cloneField[idx][name] = value
            if (!cloneField[idx][name == "lbs" ?  "reps" : "lbs"]  && !cloneField[idx][name]){
                cloneField[idx]["result"] = value
            }else{
                if (!cloneField[idx][name]) {
                    const dummyName = name == "lbs" ? "reps" : "lbs"
                    cloneField[idx]["result"] = cloneField[idx][dummyName]
                }else{
                    cloneField[idx]["result"] = value
                }
            }
        }
        else{
            cloneField[idx]["result"] = e?.target?.value
        }
        setFields(cloneField)
        setMatError({})
        setError({})
    }
    const valDropDown = () =>{
        let valFiled = statReportField,
            valError = error,
            valStatus = true
        if (!valFiled?.statsType){
            valError['statsType'] = "Please fill the required field"
            setError({...valError})
            valStatus = false
            return valStatus
        }
        else if (!valFiled?.eventType){
            valError['eventType'] = "Please fill the required field"
            setError({...valError})
            valStatus = false
            return valStatus
        }
        else if (eventORG && !valFiled?.organizationname){
            valError['organizationname'] = "Please fill the required field"
            setError({...valError})
            valStatus = false
            return valStatus
        }
        else if (isTechOpen && !valFiled?.techComp && (techCompArr?.length > 0)){
            valError['techComp'] = "Please fill the required field"
            setError({...valError})
            valStatus = false
            return valStatus
        }
        else if (!!statReportField?.weblink){
            if (!!statReportField?.weblink?.includes('http') ||
                !!statReportField?.weblink?.includes('https') ||
                !!statReportField?.weblink?.includes("www.")){
                valError['weblink'] = ""
                setError({...valError})
            }else{
                valError['weblink'] = "Please enter valid Weblink"
                setError({...valError})
                valStatus = false
                return valStatus
            }
        }
        return valStatus
    }
    const valCard = () =>{
        let matErr = []
        fields?.map((mt, idx)=>{
            if (statReportField?.statsType != 2 ? !mt?.result : (!mt?.jumbidinfeet && !mt?.jumbidininches)){
                if (statReportField?.statsType == 3){
                    matErr?.splice(idx,0, {lbs:"Please enter value", reps: "Please enter value"})
                }
                else if (statReportField?.statsType == 2){
                    matErr?.splice(idx,0, {jumbidinfeet:"Please enter value", jumbidininches: "Please enter value"})
                }
                else{
                    matErr?.splice(idx,0, {error:"Please enter value"})
                }
            }
        })
        if (matErr?.length  == fields?.length){
            setMatError(matErr)
            return false
        }else{
            const matValErr = []
            fields?.map((mtVal, idx)=>{
                if (statReportField?.statsType != 2 && statReportField?.statsType != 3){
                    if (mtVal?.id == matCardArr[idx]?.id){
                        if (!!mtVal?.result && (parseFloat(mtVal?.result) < parseFloat(matCardArr[idx]?.minimumvalue) || parseFloat(mtVal?.result) > parseFloat(matCardArr[idx]?.maximumvalue))){
                                matValErr[idx] = {error:`Please enter value in range ${matCardArr[idx]?.minimumvalue}-${matCardArr[idx]?.maximumvalue}`}
                        }
                        if (!!mtVal?.result && mtVal?.totalMat >=5){
                            matValErr[idx] = {error:`Max records limit reached`}
                        }
                    }
                }
                else if (statReportField?.statsType == 2){
                    if (mtVal?.id == matCardArr[idx]?.id){
                        if (!!mtVal?.jumbidinfeet || !!mtVal?.jumbidininches){
                            if (!mtVal?.jumbidinfeet){
                                matValErr[idx] = { jumbidinfeet: `Please enter value`}
                            }
                            else  if (!mtVal?.jumbidininches){
                                matValErr[idx] = { jumbidininches: `Please enter value`}
                            }
                            else  if (mtVal?.totalMat >=5){
                                matValErr[idx] = {
                                    jumbidinfeet: !!mtVal?.jumbidinfeet ?  `Max records limit reached` : "",
                                    jumbidininches: !!mtVal?.jumbidininches ?  `Max records limit reached` : ""
                                }
                            }
                        }
                    }
                }
                else if (statReportField?.statsType == 3){
                    if (mtVal?.id == matCardArr[idx]?.id){
                        if (!!mtVal?.lbs || !!mtVal?.reps){
                            const lbsvalue = !!mtVal?.lbs ? parseFloat(mtVal?.lbs)  : 0,
                                repsValue = !!mtVal?.reps ? parseFloat(mtVal?.reps) : 0
                        if (
                                ((parseFloat(lbsvalue) < parseFloat(matCardArr[idx]?.minimumvalue) || parseFloat(lbsvalue) > parseFloat(matCardArr[idx]?.maximumvalue)))
                            ) {
                                matValErr[idx] = {lbs:`Please enter value in range ${matCardArr[idx]?.minimumvalue}-${matCardArr[idx]?.maximumvalue}`}
                            }
                         if (
                                (parseFloat(repsValue) < parseFloat(matCardArr[idx]?.minimumvalue) || parseFloat(repsValue) > parseFloat(matCardArr[idx]?.maximumvalue))
                            ){
                                matValErr[idx] = {reps:`Please enter value in range ${matCardArr[idx]?.minimumvalue}-${matCardArr[idx]?.maximumvalue}`}
                            }
                         if (!mtVal?.lbs){
                                matValErr[idx] = {lbs: "Please enter the value"}
                            }
                         if (!mtVal?.reps){
                                matValErr[idx] = {reps: "Please enter the value"}
                            }
                         if (mtVal?.totalMat >= 5){
                             matValErr[idx] = {lbs: !!mtVal?.lbs ?  `Max records limit reached` : "",
                                 reps:!!mtVal?.reps ?  `Max records limit reached` : "" }
                         }
                        }
                    }
                }
            })
            if (matValErr?.length > 0){
                setMatError(matValErr)
                return false
            }else{
                setMatError(matValErr)
                return true
            }
        }
    }
    const saveVidPhoto = async () => {
        let formData = new FormData();
        formData.append("file", statReportField?.statsVidPhoto);
        let _resVidPhoto = await fetchMethod(isImage ? '/api/S3Containers/staging.storage.sportsforce/upload' : '/upload', formData,"POST", true)
        if (_resVidPhoto?.status == 200) {
            let obj = {
                userId: getUserInfo() ? getUserInfo().id : 0,
                photosAndVideosArray: [
                    {
                        name: _resVidPhoto.data?.result?.files?.file?.[0]?.name ?? "ER",
                        type: isImage ? 0 : 1,
                        position: null,
                        jerseyno: null,
                        jerseycolor: null,
                        albumId: null,
                        tagId: null,
                        originalname: _resVidPhoto.data?.result?.files?.file?.[0]?.name ?? "ER",
                        videophotosuploadedtype: "Uploaded",
                    },
                ],
                statid: 0,
                isvisible: 1,//it should be 1 by default
                uploadedfrom: "stats" //this key is added as per change doc in sept 15/9/2022
            }
            const res = await fetchMethod('/api/Uploadedvideoandphotos/addPhotosAndVideos', obj, 'post', true)
            // if (res?.status == 200){
            return {
                upload: _resVidPhoto.data?.result?.files?.file?.[0],
                uploadTable: res?.data?.response?.data,
                status: true
            }
        // }
        }else{
            return { status : false, upload: null, uploadTable : null}
            setIsLoading(false)
        }
    }
    const onSubmit = async () =>{
        const validation = valDropDown()
        const matCardVal = valCard()
        if (validation && matCardVal){
            setIsLoading(true)
            let uploadData = !!statReportField?.statsVidPhoto ? await saveVidPhoto() : {status : true, data: null}
            if (uploadData?.status){
                const finalobjArr = []
                await fields?.map(filArr =>{
                    if (!!filArr?.result){
                        finalobjArr?.push({
                            statmatrixmappingid: filArr?.id,
                            result: filArr?.statsmatrixid == 3 ?
                                (((parseFloat(filArr.reps) / 30) + 1) * parseFloat(filArr.lbs)).toFixed(2).toString() : filArr?.result,
                            testdate: statReportField?.date,
                            isvideoorlink: !!uploadData?.upload ? isImage ? 3 : 1 : null,
                            videoname: uploadData?.upload?.name,
                            weblink: !!statReportField?.weblink ? statReportField?.weblink : "",
                            eventtypeid: !!statReportField?.eventType ? statReportField?.eventType : null,
                            orgid: eventORG ? statReportField?.organizationname?.id : null,
                            companyid: isTechOpen ? statReportField?.techComp : null,
                            userid: getUserInfo()?.id,
                            photovideoid: uploadData?.uploadTable?.id ?? null,
                            jumbidinfeet: (filArr?.statsmatrixid == 2) ? !!filArr?.jumbidinfeet ? filArr?.jumbidinfeet : 1 : null,
                            jumbidininches: (filArr?.statsmatrixid == 2) ?  !!filArr?.jumbidininches ? filArr?.jumbidininches : 1 : null,
                            jumpincentimeter: (filArr?.statsmatrixid == 2) ? filArr?.jumpincentimeter : null,
                            lasertime: (filArr?.statsmatrixid == 1 && statReportField?.techComp == 8) ? 1 : 0,
                            reps: (filArr?.statsmatrixid == 3) ? filArr?.reps ?? "1" : null,
                            lbs: (filArr?.statsmatrixid == 3) ? filArr?.lbs ?? "1" : null,
                            heightinfeetid: filArr?.heightinfeetid ?? null,
                            heightininchecsid: filArr?.heightininchecsid ?? null,
                            weight: filArr?.weight ?? null
                        })
                    }
                })
                const finalObj = {data: finalobjArr},
                    _resSaveStatdetail = await fetchGraphMethod(saveStatsDetailCreateReport, finalObj, true)
                if (_resSaveStatdetail?.status == 200){
                        setIsLoading(false)
                    setIsPop(true)
                }else {
                    setIsLoading(false)
                }
            }
        }
    }
    const onPopUpClose = (value) =>{
        if (value == "cross"){
            setIsPop(false)
            setFields({})
            setMatCardArr([])
            setStatReportField({date: moment(new Date()).format('YYYY-MM-DD')})
            window?.scrollTo(0,0)
        }
        else if (value == "NAG"){
            setIsPop(false)
            history?.push('/St_view')
        }
    }
    const handlePlayTutorials = (data = "2enW8lkaANA") => {
        setVideoData(data);
        setVideoOpen(true);
    };
    return(
        <>
             <BackdropLoader open={isLoading}/>
            <div className="pageContent">
                <div className="editProfile">
                    <input className="fakeInput" type="text" ref={fakeInput} />
                    <div className="container">
                        <div
                            id="wrapper"
                            className={`flex ${sideMenuOpen ? "inactive" : ""}`}
                        >
                            <SubHeader hamburger={onSideMenuClick} buttonHide={false} />
                            <div className="sidebar">
                                <LeftSideEditProfile />
                            </div>
                            <div className="editSection">
                                <div className="flexRow">
                                    <div className="title">Report Stats</div>
                                    <div className="btnWrap">
                                        <button className="btn btn-statreport"
                                                style={{cursor:"not-allowed"}}
                                        type={'button'}
                                        disabled
                                        // onClick={()=>handlePlayTutorials()}
                                        >
                                      <span className="icon-box">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 17">
                                          <path id="Polygon_15" data-name="Polygon 15" d="M8.5,0,17,12H0Z"
                                                transform="translate(12) rotate(90)" fill="#fff"></path>
                                        </svg>
                                      </span>
                                            Play Tutorial
                                        </button>
                                    </div>
                                </div>
                                <div className="check-list-sec statRepotData">
                                    <div className="editprofile-common-form">
                                        <form>
                                            <div className="fieldBox">
                                                <select
                                                    value={statReportField?.statsType ?? ""}
                                                    name={'statsType'}
                                                    onChange={(e)=>onHandleChange(e)}>
                                                    <option value={""}>Select stats type</option>
                                                    {metListData?.length > 0 &&
                                                    metListData?.map(el => <option value={el?.id}>{el?.matrixname ?? ""}</option>)
                                                    }
                                                </select>
                                                {!!error?.statsType && <div className={'error'}>{error?.statsType ?? ""}</div>}
                                            </div>
                                            {!!statReportField?.statsType &&
                                            <div className="fieldBox">
                                                <select
                                                    value={statReportField?.eventType ?? ""}
                                                    onChange={(e)=>onHandleChange(e)}
                                                    name={'eventType'}
                                                >
                                                    <option value={""}>Select event type</option>
                                                    {eventTypeArr?.length > 0  &&
                                                    eventTypeArr?.map(el => <option value={el?.eventtypeid ?? "ER"}>
                                                        {el?.statseventtypemappingeventtypeidmaprel?.Eventcategories?.[0]?.categoryname ?? "ER"}</option>)
                                                    }
                                                </select>
                                                {!!error?.eventType && <div className={'error'}>{error?.eventType ?? ""}</div>}
                                            </div>
                                            }
                                            {(!!statReportField?.statsType && !!statReportField?.eventType && isTechOpen) &&
                                            <div className="fieldBox">
                                                <select
                                                    value={statReportField?.techComp ?? ""}
                                                    onChange={(e)=>onHandleChange(e)}
                                                    name={'techComp'}
                                                >
                                                    <option value={""}>Select tech company</option>
                                                    {techCompArr?.length > 0 &&
                                                    techCompArr?.map(el =><option value={el?.companystatsmappingtechcompanyidmaprel?.Stattechcompanies?.[0]?.id ?? "ER"}>
                                                        {el?.companystatsmappingtechcompanyidmaprel?.Stattechcompanies?.[0]?.name}
                                                    </option>)
                                                    }
                                                </select>
                                                {!!error?.techComp && <div className={'error'}>{error?.techComp ?? ""}</div>}
                                            </div>
                                            }
                                            {(!!statReportField?.statsType  && !!statReportField?.eventType && eventORG) &&
                                            <div className="fieldBox">
                                                <MuiAutoComplete
                                                    id="eventOrg"
                                                    freeSolo={true}
                                                    options={allOrg}
                                                    getOptionLabel={(option) =>
                                                        option.eventorgname ? option.eventorgname : ""
                                                    }
                                                    value={statReportField?.eventOrganisation || ""}
                                                    onChange={(event, newValue) => {
                                                        handleForEventOrg(
                                                            { target: { value: newValue } },
                                                            "organizationname"
                                                        );
                                                    }}
                                                    onChangeObj={(e, newValue) => {
                                                        onSelectEventOrg(
                                                            { target: { value: newValue } },
                                                            "organizationname"
                                                        );
                                                    }}
                                                    inputPlaceholder="Enter event organization"
                                                />
                                                {!!error?.organizationname && <div className={'error'}>{error?.organizationname ?? ""}</div>}
                                            </div>
                                            }
                                            {matCardArr?.length > 0 &&
                                            <div className={(statReportField?.statsType == 2 || statReportField?.statsType == 3) ?
                                                 "fieldBox" : statReportField?.statsType == 4 ? "fieldBox flexBox three-col-row two-col-row"
                                                    :   "fieldBox flexBox three-col-row"}>
                                                {matCardArr?.map((mt, idx) =>{
                                                    if (mt?.statsmatrixid == 2){
                                                        return (
                                                            <div className={'half'}>
                                                                <label style={{fontWeight:"600"}}>{mt?.tabname ?? "ER"}</label>
                                                                <div className={'fieldBox flexBox'}
                                                                     style={{marginBottom:"10px"}}
                                                                >
                                                                    <div className={'half'}>
                                                                        <label>Enter Result (Ft)</label>
                                                                        <select
                                                                            value={fields?.[idx]?.jumbidinfeet}
                                                                            name = "jumbidinfeet"
                                                                            onChange={(e)=>onHandleMatCard(e, mt, idx)}
                                                                        >
                                                                            <option value={""}>None</option>
                                                                            {
                                                                                heightInFeetList?.length > 0 &&
                                                                                heightInFeetList?.map((ht) =>{
                                                                                    if(mt?.tabname == "Vertical Jump"){
                                                                                        if (ht?.feetvalue >= 1 && ht?.feetvalue <= 5){
                                                                                            return <option value={ht?.id}>{ht?.feetvalue}</option>
                                                                                        }
                                                                                    }else{
                                                                                        return <option value={ht?.id}>{ht?.feetvalue}</option>
                                                                                    }
                                                                                })
                                                                            }
                                                                        </select>
                                                                        {!!matError?.[idx]?.jumbidinfeet && <div className={'error'}>{matError?.[idx]?.jumbidinfeet ?? ""}</div>}
                                                                    </div>
                                                                    <div className={'half'}>
                                                                        <label>Enter Result (In) </label>
                                                                        <select
                                                                            name = "jumbidininches"
                                                                            value={fields?.[idx]?.jumbidininches}
                                                                            onChange={(e)=>onHandleMatCard(e, mt, idx)}
                                                                        >
                                                                            <option value={""}>None</option>
                                                                            {
                                                                                heightInInchList?.length > 0 &&
                                                                                heightInInchList?.map(ht =><option value={ht?.id}>{ht?.inchvalue}</option>)
                                                                            }
                                                                        </select>
                                                                        {!!matError?.[idx]?.jumbidininches && <div className={'error'}>{matError?.[idx]?.jumbidininches ?? ""}</div>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    if (mt?.statsmatrixid == 3){
                                                        return (
                                                            <div className={'half'}>
                                                                <label style={{fontWeight:"600"}}>{mt?.tabname ?? "ER"}</label>
                                                                <div className={'fieldBox flexBox'}
                                                                style={{marginBottom:"10px"}}
                                                                >
                                                                    <div className={'half'}>
                                                                        <label>Enter Result (LBS)</label>
                                                                        <input
                                                                            value={fields?.[idx]?.lbs}
                                                                            name = "lbs"
                                                                            type={'number'}
                                                                            onChange={(e)=>onHandleMatCard(e, mt, idx)}
                                                                        />
                                                                        {!!matError?.[idx]?.lbs && <div className={'error'}>{matError?.[idx]?.lbs ?? ""}</div>}
                                                                    </div>
                                                                    <div className={'half'}>
                                                                        <label>Enter Result (REPS)</label>
                                                                        <input
                                                                            value={fields?.[idx]?.reps}
                                                                            name = "reps"
                                                                            type={'number'}
                                                                            onChange={(e)=>onHandleMatCard(e, mt, idx)}
                                                                        />
                                                                        {!!matError?.[idx]?.reps && <div className={'error'}>{matError?.[idx]?.reps ?? ""}</div>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    else {
                                                        return(
                                                            <>
                                                                <div className={statReportField?.statsType == 4 ? "half": "three-col"}>
                                                                    <label>{mt?.tabname ?? "ER"}</label>
                                                                    <input
                                                                        type="number"
                                                                        value={fields?.[idx]?.result}
                                                                        placeholder={mt?.unit ?? "ER"}
                                                                        onChange={(e)=>onHandleMatCard(e, mt, idx)}
                                                                    />
                                                                    {!!matError?.[idx]?.error && <div className={'error'}>{matError?.[idx]?.error ?? ""}</div>}
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                })}
                                            </div>
                                            }
                                            <div className="fieldBox">
                                                <label>Enter Performance Date</label>
                                                <input
                                                    type="date"
                                                    name="date"
                                                    placeholder="mm/dd/yyyy"
                                                    value={statReportField?.date ?? ""}
                                                    onChange={(e)=>onHandleChange(e)}
                                                    max={moment(new Date()).format('YYYY-MM-DD')}
                                                />
                                                {!!error?.date && <div className={'error'}>{error?.date ?? ""}</div>}
                                            </div>
                                            <div className="fieldBox">
                                                <label>Weblink</label>
                                                <input
                                                    value={statReportField?.weblink ?? ""}
                                                    type="url"
                                                    name="weblink"
                                                    placeholder="e.g https://www.sportsforce.io"
                                                    onChange={(e)=>onHandleChange(e)}
                                                />
                                                {!!error?.weblink && <div className={'error'}>{error?.weblink ?? ""}</div>}
                                            </div>
                                            {/* <div className="fieldBox">
                                                <div className="stats-uploadbox">
                                                    <label className="uploadbtn" htmlFor="uploadvideophoto">Upload
                                                        image / video</label>
                                                    <input
                                                        id="uploadvideophoto"
                                                        hidden=""
                                                        type="file"
                                                        placeholder="Upload image / video"
                                                        naame={"statsVidPhoto"}
                                                        accept="video/mp4,video/x-m4v,video/*,image/png,image/jpeg, image.jpg"
                                                        onChange={(e)=>onHandleChange(e, 'statsVidPhoto')}
                                                    />
                                                    <button
                                                        style={!!statReportField?.statsVidPhoto ? { display:"block" } :
                                                            {display:"none"} }
                                                        className="video-trashbtn"
                                                        type={'button'}
                                                        onClick={() => onHandleChange(null, "statsVidPhoto")}
                                                    >Delete
                                                    </button>
                                                    {!!statReportField?.statsVidPhoto?.name && (
                                                        <div className="error text-center">{statReportField?.statsVidPhoto?.name ?? ""}</div>
                                                    )}
                                                </div>
                                            </div> */}
                                            <div className="fieldBox buttonBox">
                                                <button
                                                className={'btn green'}
                                                    // className="btn green disabled"
                                                        type={'button'}
                                                onClick={()=>onSubmit()}
                                                >Submit</button>
                                                <button
                                                    disabled={true}
                                                    type={'button'}
                                                    className="btn green-outline">Request Verification</button>
                                            </div>
                                            {/*<div className="form-button-box d-block border-0 pt-0 mb-0">*/}
                                            {/*    <a className="btn orange" href="#/stats">Back</a>*/}
                                            {/*</div>*/}
                                        </form>
                                    </div>
                                </div>

                                <div className="report-confirmationMessage"
                                     style={isSuccess ? {display:"block"} : {display:"none"}}
                                >
                                    <div className="checkIcon">
                                        <svg id="check-circle-fill" xmlns="http://www.w3.org/2000/svg" width="24"
                                             height="24" viewBox="0 0 24 24">
                                            <g id="Group_2346" data-name="Group 2346">
                                                <path id="Path_2318" data-name="Path 2318"
                                                      d="M24,12A12,12,0,1,1,12,0,12,12,0,0,1,24,12ZM18.045,7.455a1.125,1.125,0,0,0-1.62.033l-5.21,6.637L8.076,10.984a1.125,1.125,0,0,0-1.59,1.59l3.969,3.971a1.125,1.125,0,0,0,1.619-.03L18.062,9.03a1.125,1.125,0,0,0-.015-1.575Z"
                                                      fill="#fff"/>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="text" >Your stats has been submitted</div>
                                    <button className="closeBtn">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14.047" height="14.046"
                                             viewBox="0 0 14.047 14.046">
                                            <g id="close" transform="translate(-9.034 -9.036)">
                                                <g id="Group_2347" data-name="Group 2347"
                                                   transform="translate(9.034 9.036)">
                                                    <path id="Path_2319" data-name="Path 2319"
                                                          d="M10.637,9.311A.939.939,0,1,0,9.31,10.638l5.421,5.421L9.309,21.48a.939.939,0,1,0,1.327,1.327l5.422-5.421,5.421,5.421a.939.939,0,1,0,1.327-1.327l-5.421-5.421,5.421-5.421a.939.939,0,1,0-1.327-1.327l-5.421,5.421L10.637,9.31Z"
                                                          transform="translate(-9.034 -9.036)" fill="#fff"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog
            open={isPop}
            onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                    onPopUpClose("cross");
                }
            }}
            className="matDialogCustom"
            >
                <ReportPop
                isClose={onPopUpClose}
                />
            </Dialog>
            <Dialog open={videoOpen} maxWidth={"lg"} className={"videoOverlayWrap matDialogCustom"}
                    onClose={(event, reason)=> {
                        if (reason == "backdropClick" || reason == "escapeKeyDown") {
                            setVideoOpen(false)
                        }
                    }}
            >
                <VideoOverlay
                    onVideoClose={() => setVideoOpen(false)}
                    videoData={videoData}
                />
            </Dialog>
        </>
    )
}
const VideoOverlay = ({ onVideoClose, videoData = null }) => {
    const sourceURL = isNaN(videoData)
        ? `https://www.youtube.com/embed/${videoData ? videoData : "aqz-KE-bpKQ"}`
        : `https://player.vimeo.com/video/${videoData && videoData}`;
    return <>
        <IconButton
            aria-label="close"
            className={"closeBtnVideo"}
            onClick={onVideoClose}
            size="large">
            <CloseIcon />
        </IconButton>
        <iframe
            width="700"
            height="400"
            src={sourceURL}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        ></iframe>
    </>;
};