import React, { useState, useEffect, useDebugValue, useCallback } from "react";
import "../assets/css/statusUpdate.scss";
import { Dialog } from "@mui/material";
import {imageDownloadUrl, imageUploadUrl, API_URL, awsVideoSourceBucket} from "../../../../../../config";
import uploadToS3 from "../../../../../../utils/uploadToS3";
import {
  getAuthToken,
  getUserInfo,
} from "../../../../../../services/authService";
import axios from "axios";
import { connect } from "react-redux";
import { saveMediaQuery, savePostQuery, updatePostsQuery } from "./PostsQuery";
import {
  savePostStart,
  savePostSuccess,
} from "../../../../../../redux/athlete/dashboard/homepage/savePost";
import messagePopup from "../../../../../../utils/messagePopup";
import warningMessagePopup from "../../../../../../utils/warningMagPop";
import userImg from "./../assets/img/user.svg";
import { saveVideoPhotoStart } from "../../../../../../redux/athlete/dashboard/editUserProfile/photo-video/saveVideoPhoto";
import IconButton from "@mui/material/IconButton";
import swal from "sweetalert";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckInModal from "./CheckInModal";
import Map from "../../colleges/Map";
import CheckInPng from "../../homepage/assets/img/CheckInPng.png";
import SharePost from "./SharePost";
import { validURL } from "../../../../../../utils/validationUtils";
import {fetchMethod} from "../../../../../../services/httpService";
import { sendVideoPhotoNotification } from "../../../../../../utils/sendNotificationToAdvisor";
import toastr from "toastr";
import InlineLoader from "../../../../../common/InlineLoader";
import { messages } from "../../editUserProfile/videoPhoto/constants";

const alphabet = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

const MapWrapper = ({ latlong }) => {
  return (
    <>
      {latlong.lat ? (
        <Map markers={[{ latitude: latlong.lat, longitude: latlong.long }]} />
      ) : null}
    </>
  );
};

function PostUpdate(props) {
  const initialState = {
    statusUpdate: "",
    isPublic: "public",
  };
  const [fields, setFields] = useState(initialState);
  const [userPic, setUserPic] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [linkStatus, setLinkStatus] = useState(false);
  const [postLinkList, setPostLinkList] = useState([""]);
  const [showCheckModal, setCheckShowModal] = useState(false);
  // const [error, setError] = useState('')
  // const [CheckModalStatus, showCheckModalStatus] = useState(false);
  const [showSharePostModal, setShowSharePostModal] = useState(false);
  const [errorsArray, setErrorsarray] = useState([]);
  const [locationName, setLocationName] = useState(false);
  const [latlong, setLatLong] = useState({ lat: "", long: "" });
  const [modalData, setModalData] = useState(null)
  const [isClickSubmitDis, setIsClickSubmitDis] = useState(0)
  //this is post post update toggle
  const [isPostUpdate, setIsPostUpdate] = useState(false);
  let count = 0;
  const handleShareModal = async (data) => {
    const resFeed  = await fetchMethod(`/api/Userposts/myFeed?loginUserId=${getUserInfo()?.id ?? 0}&firstDropDownValue=${2}&secondDropDownValue=${1}&limit=${20}&offset=${0}`
        ,"","GET",true)
    if(resFeed?.status == 200){
      const postData = resFeed?.data?.response?.myFeedData?.allPost ?? [],
          filPost = postData.filter(el => el?.id == data?.shareData?.postDetail?.id)
      if(filPost?.length > 0){
        setModalData(filPost[0])
        setShowSharePostModal((prev_state) => !prev_state);
      }
    }else{
      console.log("my feed api failed")
    }
  };
  const OpenModal = () => {
    setCheckShowModal(true);
    setLatLong({ lat: "", long: "" });
  };
  const setLocation = (value) => {
    const lat = value.placeLat;
    const long = value.placeLng;
    setLatLong({ lat: lat, long: long });
    if (value.city) {
      setLocationName(value.city);
    } else {
      setLocationName(value.country);
    }
    setCheckShowModal(false);
  };
  useEffect(() => {
    if (props.savePost.loading === false) {
      if (props.savePost.data) {
        if (props.savePost.data.ishome !== "false") {
          if (props?.savePost?.data?.shareData?.mediaData?.[0]?.typeofpost == 2 ||
              props?.savePost?.data?.shareData?.mediaData?.[0]?.typeofpost == 1
          ){
            handleShareModal(props?.savePost?.data);
          }
          else if (props?.firstDropDown == 2){
            console.log("uploaded data")
          }
        }
        props.savePostSuccess("");
        setShowModal(false); // Close dialog after successful upload
      }
      resetData(); // Reset form data
    }
  }, [props.savePost.loading]);

  useEffect(() => {
    if (
      props.savePost.loading === false &&
      props.savePost.data &&
      !props.savePost.error
    ) {
      resetData();
    }
  }, [props.savePost]);

  useEffect(() => {
    if (props.athleteData.data) {
      const { onboardingData, athletesData } = props.athleteData.data;
      const tempPic = onboardingData
        ? onboardingData.profileimages
          ? `${imageDownloadUrl}/${onboardingData.profileimages}`
          : ""
        : "";
      setUserPic(tempPic);
    }
  }, [props.athleteData.data]);

  const handleChange = (field, e) => {
    if (field === "photos") {
      if (e.target.files) {
        setShowModal(true);
        let posts = fields.posts ? fields.posts : [];
        let selectedFileArray = [...selectedFiles];
        let filesArray = [...posts];
        //this variable is used for setting state when all image is valid
        let status = true;
        Array.from(e.target.files).map((file) => {
          //this fileExtension and if else condition is used for image validation
          let fileExtension = file.name
            .split(".")
            [file.name.split(".").length - 1]?.toLowerCase();
          if (
            fileExtension == "jpg" ||
            fileExtension == "png" ||
            fileExtension == "jpeg"
          ) {
            const fileArr = file.name.split(".");
            if (count > 25) count = 0;
            fileArr[0] = `${new Date().getTime()}` + alphabet[count++];
            // fileArr[0] = parseInt(Date.now()) * 100;
            //the above code is used for getting the unique value earlier value is repeated
            // fileArr[0] = new Date().getTime();
            const newFile = new File([file], fileArr.join("."), {
              type: file.type,
              lastModified: file.lastModified,
            });

            filesArray = [
              ...filesArray,
              {
                typeofpost: 1,
                postimagevideoandlinkurl: URL.createObjectURL(newFile),
              },
            ];
            selectedFileArray = [
              ...selectedFileArray,
              {
                typeofpost: 1,
                file: newFile,
              },
            ];
          } else {
            toastr.error("Photo format must be JPEG, JPG or PNG", "", {
              progressBar : true,timeOut: 2000,
              closeButton: true, positionClass: "toast-top-full-width",
              showEasing: "swing", fadeIn: 40000,
              preventDuplicates: true,
            })
            // swal({
            //   header: "",
            //   text: "Photo format must be JPEG, JPG or PNG",
            //   icon: "error",
            // });
            //below state is for modal open
            // setShowModal(false);
            status = false;
          }
        });
        if (status) {
          fields["posts"] = filesArray;
          setFields({
            ...fields,
          });
          setSelectedFiles(selectedFileArray);
        }
      }
    }
    else if (field === "videos") {
      if (e.target.files) {
        setShowModal(true);
        try {
          let posts = fields.posts ? fields.posts : [];
          let selectedFilesT = [...selectedFiles];
          let vidStatus = true;
          const response = Array.from(e.target.files).map(async (file) => {
            //this fileExtension and if else condition is used for video validation
            let fileExtension = file.name
              .split(".")
              [file.name.split(".").length - 1]?.toLowerCase();
            if (
              fileExtension == "mp4" ||
              fileExtension == "3g2" ||
              fileExtension == "3gp" ||
              fileExtension == "avi" ||
              fileExtension == "flv" ||
              fileExtension == "m4v" ||
              fileExtension == "mkv" ||
              fileExtension == "mov" ||
              fileExtension == "mpg" ||
              fileExtension == "mts" ||
              fileExtension == "m2ts" ||
              fileExtension == "mpeg" ||
              fileExtension == "wmv"
            ) {
              const fileSize = parseInt(file?.size) / (1024 * 1024);
              if ((parseFloat(fileSize).toFixed(3)) > 150){
                toastr.error("Max Limit 150MB", "", {
                  progressBar : true,timeOut: 2000,
                  closeButton: true, positionClass: "toast-top-full-width",
                  showEasing: "swing", fadeIn: 40000,
                  preventDuplicates: true,
                })
                return
              }
              posts.push({
                typeofpost: 2,
                postimagevideoandlinkurl: URL.createObjectURL(file),
              });
              const fileArr = file.name.split(".");
              if (count > 25) count = 0;
              fileArr[0] = `${new Date().getTime()}` + alphabet[count++];
              // fileArr[0] = parseInt(Date.now()) * 100;
              //the above code is used for getting the unique value earlier value is repeated
              // fileArr[0] = new Date().getTime();
              const newFile = new File([file], fileArr.join("."), {
                type: file.type,
                lastModified: file.lastModified,
              });
              selectedFilesT.push({
                typeofpost: 2,
                file: newFile,
              });
            } else {
              swal({
                header: "",
                text: "Please Select only videos format(3g2, 3gp, avi, flv, m4v, mkv, mov, mp4, mpg, mts, m2ts, mpeg, wmv)",
                icon: "error",
              });
              setShowModal(false);
              vidStatus = false;
            }
            if (vidStatus) {
              fields["posts"] = posts;
              setFields({
                ...fields,
              });
              setSelectedFiles(selectedFilesT);
            }
          });
        } catch (ex) {
          console.log("ERROR: ", ex);
        }
      }
    }
    else if (field === "Link") {
      if (e.target.value) console.log("Link", e.target.value);
    }
    else {
      setFields({
        ...fields,
        [field]: e.target.value,
      });
    }
  };

  const uploadImages = async () => {
    let onlyText = true;
    
    if (selectedFiles && selectedFiles.length) {
      onlyText = false;
      
      // Handle videos first
      const videoFiles = selectedFiles.filter(file => file.typeofpost === 2);
      if (videoFiles.length > 0) {
        for (const videoFile of videoFiles) {
          try {
            // First create a record with null name and isActive = 0
            const response = await axios({
              url: API_URL + '/api/Uploadedvideoandphotos/addPhotosAndVideos',
              method: "POST",
              data: {
                userId: getUserInfo() ? getUserInfo().id : 0,
                photosAndVideosArray: [
                  {
                    name: videoFile.file.name,
                    type: 1,
                    position: null,
                    jerseyno: null,
                    jerseycolor: null,
                    albumId: null,
                    pressid: null,
                    tagId: null,
                    originalname: videoFile.file.name,
                    videophotosuploadedtype: 'Uploaded',
                    isphotosorvideoslik: 1,
                  }
                ],
                isvisible: 1,
                active: 0,
                uploadedfrom: 'dashboard',
                postdescription: fields.statusUpdate
              },
              headers: {
                access_token: getAuthToken(),
              },
            });

            if (response && response.status === 200) {
              // Get the id of the record created
              const id = response.data.response.data.id;

              // Upload video to S3
              const uploadParams = {
                Bucket: awsVideoSourceBucket,
                Key: `assets01/${id}_${videoFile.file.name}`,
                Body: videoFile.file,
              };

              setIsClickSubmitDis(true);
              await uploadToS3(uploadParams);
              setIsClickSubmitDis(false);
              console.log('Upload Success');
              setShowModal(false);
              // Reset preview states
              setFields({
                ...fields,
                posts: []
              });
              setSelectedFiles([]);

              toastr.success(messages.UPLOADED_SUCCESSFULLY, '', {
                progressBar: true,
                timeOut: 5000,
                closeButton: true,
                positionClass: 'toast-top-full-width',
                showEasing: 'swing',
                fadeIn: 1000,
                preventDuplicates: true,
              });
            } else {
              toastr.error("Failed to upload video", "", {
                progressBar: true,
                timeOut: 2000,
                closeButton: true,
                positionClass: "toast-top-full-width",
                showEasing: "swing",
                fadeIn: 40000,
                preventDuplicates: true,
              });
            }
          } catch (error) {
            toastr.error("Failed to upload video", "", {
              progressBar: true,
              timeOut: 2000,
              closeButton: true,
              positionClass: "toast-top-full-width",
              showEasing: "swing",
              fadeIn: 40000,
              preventDuplicates: true,
            });
          }
        }
      }

      // Handle photos
      const photoFiles = selectedFiles.filter(file => file.typeofpost === 1);
      if (photoFiles.length > 0) {
        let fileNameArr = [];
        for (const photoFile of photoFiles) {
          try {
            let formData = new FormData();
            formData.append("file", photoFile.file);
            const response = await axios({
              url: imageUploadUrl,
              method: "POST",
              data: formData,
              headers: {
                access_token: getAuthToken(),
              },
            });
            
            if (response && response.status === 200) {
              const pathUrl = response.data.result?.files?.file[0]?.name || "";
              fileNameArr.push({
                postimagevideoandlinkurl: pathUrl,
                typeofpost: 1,
              });
            }
          } catch (error) {
            toastr.error("Failed to upload photo", "", {
              progressBar: true,
              timeOut: 2000,
              closeButton: true,
              positionClass: "toast-top-full-width",
              showEasing: "swing",
              fadeIn: 40000,
              preventDuplicates: true,
            });
          }
        }

        if (fileNameArr.length > 0) {
          let tempObj = {
            userid: getUserInfo()?.id,
            postdescription: fields.statusUpdate,
            ispublic: fields.isPublic === "public" ? 1 : 0,
            isprivate: fields.isPublic === "private" ? 1 : 0,
          };

          props.savePostStart({
            postQuery: savePostQuery,
            mediaQuery: saveMediaQuery,
            postData: { obj: tempObj },
            mediaData: fileNameArr,
            getPosts: props.getPosts,
            userActivityQuery: updatePostsQuery,
          });
        }
      }
    }
    // Handle links
    if (linkStatus && postLinkList[0]) {
      onlyText = false;
      const fileNameArr = postLinkList.map(link => ({
        postimagevideoandlinkurl: link,
        typeofpost: 3,
      }));

      let tempObj = {
        userid: getUserInfo()?.id,
        postdescription: fields.statusUpdate,
        ispublic: fields.isPublic === "public" ? 1 : 0,
        isprivate: fields.isPublic === "private" ? 1 : 0,
      };

      props.savePostStart({
        postQuery: savePostQuery,
        mediaQuery: saveMediaQuery,
        postData: { obj: tempObj },
        mediaData: fileNameArr,
        getPosts: props.getPosts,
        userActivityQuery: updatePostsQuery,
      });

      setLinkStatus(false);
      setShowModal(false);
    }
    // Handle location
    if (locationName) {
      onlyText = false;
      let tempObj = {
        userid: getUserInfo()?.id,
        lat: latlong.lat.toString(),
        lng: latlong.long.toString(),
        postdescription: fields.statusUpdate,
        ispublic: fields.isPublic === "public" ? 1 : 0,
        isprivate: fields.isPublic === "private" ? 1 : 0,
      };

      props.savePostStart({
        postQuery: savePostQuery,
        mediaQuery: saveMediaQuery,
        postData: { obj: tempObj },
        mediaData: null,
        getPosts: props.getPosts,
        userActivityQuery: updatePostsQuery,
      });
      setShowModal(false);
    }
    // Handle text-only post
    if (fields.statusUpdate && onlyText) {
      let tempObj = {
        userid: getUserInfo()?.id,
        postdescription: fields.statusUpdate,
        ispublic: fields.isPublic === "public" ? 1 : 0,
        isprivate: fields.isPublic === "private" ? 1 : 0,
      };

      props.savePostStart({
        postQuery: savePostQuery,
        mediaQuery: saveMediaQuery,
        postData: { obj: tempObj },
        mediaData: null,
        getPosts: props.getPosts,
        userActivityQuery: updatePostsQuery,
      });
      setShowModal(false);
      setIsPostUpdate(true);
    }

    setIsClickSubmitDis(0);
  };

  const handleSubmit = () => {
    if (
      (postLinkList && errorsArray && errorsArray[0]) ||
      errorsArray[1] ||
      errorsArray[2] ||
      errorsArray[3] ||
      errorsArray[4]
    ) {
      // messagePopup("", "Please enter a valid link", "warning");
      toastr.warning("Please enter a valid link", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates: true,
      })
    } else if (
      (selectedFiles && selectedFiles.length) ||
      (postLinkList && postLinkList[0] !== "") ||
      fields?.statusUpdate ||
      locationName
    ) {
      //this swal has been remove by client jira task 299 19/11/2022
      // const willSave = swal({
      //   title: "Are you sure?",
      //   text: "You want to upload the post",
      //   icon: "warning",
      //   buttons: {
      //     confirm: { text: "Ok" },
      //     cancel: "Cancel",
      //   },
      //   dangerMode: true,
      // });
      // willSave.then((uploadStatus) => {
      //   if (uploadStatus == true && uploadStatus != null) {
         setIsClickSubmitDis(isClickSubmitDis + 1)
          uploadImages();
      //   }
      // });
    } else {
      // messagePopup("", "You have not selected anything to post.", "warning");
      toastr.warning("You have not selected anything to post.", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates: true,
      })
    }
  };

  const handleModal = (type) => {
    setFields({
      statusUpdate: "",
      isPublic: "public",
    });
    setLocationName(false);
    setLatLong({ lat: "", long: "" });
    setSelectedFiles([]);
    setShowModal(!showModal);
    closeLinkField();
    //this check is for only post update by this only post is save user not able to
    // save post and vedio and photo simultaniously which is a bug
    if (type == "postUpdate") setIsPostUpdate(false);
  };

  const resetData = () => {
    setFields({
      statusUpdate: "",
      isPublic: "public",
    });
    setLocationName(false);
    setLatLong({ lat: "", long: "" });
    setSelectedFiles([]);
    setShowModal(false);
    closeLinkField();
  };

  const openLinkField = (type) => {
    setLinkStatus(true);
    const fieldsT = fields;
    //by this fields state is getting empty when user click on the post update
    // input which is bug so now it is under check
    if (type == "linkUpdate") {
      fieldsT["posts"] = [];
      setSelectedFiles([]);
    }
    setFields({
      ...fields,
    });
  };

  const closeLinkField = () => {
    setLinkStatus(false);
    setPostLinkList([""]);
    setErrorsarray([]);
  };

  const handleLinkChange = (e, index, item) => {
    // const linkCheck = e.target.value.match(/https/g)?.length;
    const result = new RegExp(
      "^(http|https|ftp)://[a-zA-Z0-9-.]+.[a-zA-Z]{2,3}(:[a-zA-Z0-9]*)?/?([a-zA-Z0-9-._?,'/\\+&amp;%$#=~])*$"
    ).test(e.target.value);
    const tempLink = postLinkList;
    tempLink[index] = e.target.value;
    setPostLinkList([...tempLink]);
    if (result || !e.target.value) {
      const linkErrors = [];
      tempLink.forEach((link, i) => {
        if (tempLink[i] && validURL(tempLink[i]) == false) {
          const error = "Please enter a valid link.";
          linkErrors[i] = error;
        } else if (!tempLink[i]) {
          linkErrors[i] = "";
        }
      });
      setErrorsarray(linkErrors);
    } else {
      const errorLink = [];
      errorLink[index] = "Please enter a valid link.";
      setErrorsarray(errorLink);
    }
  };
  const handleAddLink = (e, item) => {
    e.preventDefault();
    if (item && postLinkList.length < 5) {
      setPostLinkList([...postLinkList, ""]);
    } else {
      // messagePopup("", "You can post maximum 5 links at a time.", "error");
      toastr.error("You can post maximum 5 links at a time.", "", {
        progressBar : true,timeOut: 2000,
        closeButton: true, positionClass: "toast-top-full-width",
        showEasing: "swing", fadeIn: 40000,
        preventDuplicates: true,
      })
    }
  };

  const handleRemoveLink = (e, index) => {
    e.preventDefault();
    const tempLink = postLinkList;
    const tempErrArr = [...errorsArray];
    tempLink.splice(index, 1);
    tempErrArr.splice(index, 1);
    setPostLinkList([...tempLink]);
    setErrorsarray(tempErrArr);
  };

  const userInfo = () => {
    const locationInfo = locationName ? "is in " + locationName : "";

    const userInfo = getUserInfo()
      ? getUserInfo().FirstName +
        " " +
        getUserInfo().lastName +
        "  " +
        locationInfo
      : "";

    return userInfo;
  };
 const onSharePopClose = () =>{
   if (props?.firstDropDown == 2){
     setTimeout(()=>{
       setShowSharePostModal(false)
     },400)
     if (modalData?.allVideoAudioPost?.[0]?.typeofpost == 2 || modalData?.allVideoAudioPost?.[0]?.typeofpost == 1 ){
       window.location.reload();
     }
   }else{
     setShowSharePostModal(false)
   }
 }
  return (
    <div>
      <div className="postBox">
        <div className="postInputBox">
          <div className="imgBox">
            {userPic ? (
              <img
                className={!userPic ? "defaultUserImg" : ""}
                src={userPic}
                alt="userPic"
              />
            ) : (
              `${
                !!getUserInfo()?.FirstName
                  ? getUserInfo()?.FirstName.split("")[0].toUpperCase() || ""
                  : ""
              }${
                !!getUserInfo()?.lastName
                  ? getUserInfo()?.lastName.split("")[0].toUpperCase() || ""
                  : ""
              }`
            )}
          </div>
          <input
            type="text"
            placeholder="Post a status update"
            onClick={() => handleModal("postUpdate")}
          />
        </div>
        <div className="flexBox">
          <ul>
            <li>
              <a className="videoLink">
                <span className="icon">
                  <label htmlFor="file-input">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25.755"
                      height="18"
                      viewBox="0 0 25.755 18"
                    >
                      <defs>
                        <style></style>
                      </defs>
                      <path
                        className="a"
                        d="M24.914,4.5H3.841A2.289,2.289,0,0,0,1.5,6.75v13.5A2.3,2.3,0,0,0,3.841,22.5H24.914a2.293,2.293,0,0,0,2.33-2.25l.012-13.5A2.3,2.3,0,0,0,24.914,4.5Zm.5,16.252H3.333V6.242H25.415ZM19.06,13.5,10.865,18V9Z"
                        transform="translate(-1.5 -4.5)"
                      />
                    </svg>
                  </label>
                  <input
                    id="file-input"
                    type="file"
                    accept="video/*,.mkv,.flv,3g2,.3gp,.avi,.m4v,.mov,.mp4,.mpg,.mts,.m2ts,.mpeg,.wmv"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      handleChange("videos", e);
                      // closeLinkField();
                      e.currentTarget.value = null;
                    }}
                    multiple
                  />
                </span>
                <label htmlFor="file-input">
                  {" "}
                  <span>Video</span>
                </label>
              </a>
            </li>
            <li>
              <a
                className="linkingLink"
                onClick={() => {
                  setShowModal(true);
                  openLinkField("linkUpdate");
                }}
              >
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="31"
                    height="17"
                    viewBox="0 0 31 17"
                  >
                    <defs>
                      <style></style>
                    </defs>
                    <g transform="translate(-0.71 -9.5)">
                      <path
                        className="a"
                        d="M18.5,10.5H23a7.5,7.5,0,1,1,0,15H18.5m-5,0H9a7.5,7.5,0,1,1,0-15h4.5"
                        transform="translate(0.21)"
                      />
                      <path
                        className="a"
                        d="M12,18H25"
                        transform="translate(-2.29)"
                      />
                    </g>
                  </svg>
                </span>
                <span>Link</span>
              </a>
            </li>
            <li>
              <a className="photoLink">
                <span className="icon">
                  <label htmlFor="input">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24.079"
                      height="18"
                      viewBox="0 0 24.079 18"
                    >
                      <defs>
                        <style></style>
                      </defs>
                      <path
                        className="a"
                        d="M25.142,5.142l0,0V20.568l0,0H4.078l0,0V5.144l0,0Zm0-1.286H4.076a1.413,1.413,0,0,0-1.5,1.286V20.57a1.413,1.413,0,0,0,1.5,1.286H25.145a1.413,1.413,0,0,0,1.5-1.286V5.142a1.413,1.413,0,0,0-1.5-1.286Zm-3.01,4.5a2.113,2.113,0,0,1-2.257,1.929A2.113,2.113,0,0,1,17.62,8.356a2.113,2.113,0,0,1,2.257-1.929,2.113,2.113,0,0,1,2.257,1.929Zm1.5,10.929H5.581V16.713L10.848,9l6.02,6.429h1.5L23.64,11.57Z"
                        transform="translate(-2.571 -3.856)"
                      />
                    </svg>
                  </label>
                  <input
                    id="input"
                    type="file"
                    accept="image/png,image/jpeg, image.jpg"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      handleChange("photos", e);
                      // closeLinkField();
                      e.currentTarget.value = null;
                    }}
                    multiple
                  />
                </span>
                <label htmlFor="input">
                  {" "}
                  <span>Photo</span>
                </label>
              </a>
            </li>
            <li>
              <a className="checkInLink">
                <span
                  className="icon"
                  onClick={() => {
                    handleModal();
                    OpenModal();
                  }}
                >
                  <svg
                    className="pin-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="15.152"
                    height="21.219"
                    viewBox="0 0 15.152 21.219"
                  >
                    <g id="location" transform="translate(-6.75 -2.248)">
                      <path
                        id="Path_431"
                        data-name="Path 431"
                        d="M18.781,12.765a1.515,1.515,0,1,1-1.515-1.515A1.515,1.515,0,0,1,18.781,12.765Z"
                        transform="translate(-2.938 -2.938)"
                        fill="#ffffff"
                      ></path>
                      <path
                        id="Path_432"
                        data-name="Path 432"
                        d="M14.326,2.25A7.426,7.426,0,0,0,6.75,9.5c0,1.9.867,4.432,2.577,7.519a55.569,55.569,0,0,0,3.788,5.839,1.5,1.5,0,0,0,2.424,0,55.789,55.789,0,0,0,3.788-5.835C21.036,13.932,21.9,11.4,21.9,9.5A7.426,7.426,0,0,0,14.326,2.25Zm0,10.607a3.031,3.031,0,1,1,3.031-3.031,3.031,3.031,0,0,1-3.031,3.031Z"
                        transform="translate(0)"
                        fill="#c0321f"
                      ></path>
                    </g>
                  </svg>
                </span>{" "}
                <span
                  onClick={() => {
                    handleModal();
                    OpenModal();
                  }}
                >
                  {" "}
                  Check In{" "}
                </span>
                <input
                  id="Check-In"
                  type="Checkin"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    handleChange("CheckIn", e);
                  }}
                />
              </a>
            </li>
          </ul>
        </div>
      </div>

      <Dialog open={showModal}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  handleModal()
                }
              }}
              className="matDialogCustom matDialogCustomOverlay"
      >
        <div
          className={`modal fade statusUpdateModal show`}
          id="statusUpdateModal"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="heading">Your Status Update</h3>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={handleModal}
                >
                  <span aria-hidden="true">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15.804"
                      height="15.803"
                      viewBox="0 0 15.804 15.803"
                    >
                      <defs>
                        <style></style>
                      </defs>
                      <g className="a" transform="translate(-6.776 -6.776)">
                        <path
                          d="M8.579,7.086A1.056,1.056,0,1,0,7.086,8.579l6.1,6.1-6.1,6.1A1.056,1.056,0,1,0,8.578,22.27l6.1-6.1,6.1,6.1a1.056,1.056,0,1,0,1.493-1.493l-6.1-6.1,6.1-6.1a1.056,1.056,0,1,0-1.493-1.493l-6.1,6.1-6.1-6.1Z"
                          transform="translate(0 0)"
                        />
                      </g>
                    </svg>
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div className="profileFlexBox">
                  <div className="imgBox">
                    {!!userPic ? (
                      <img
                        className={!userPic ? "defaultUserImg" : ""}
                        src={userPic ? userPic : userImg}
                        alt="playerimg"
                      />
                    ) : (
                      `${
                        !!getUserInfo()?.FirstName
                          ? getUserInfo()
                              ?.FirstName.split("")[0]
                              .toUpperCase() || ""
                          : ""
                      }${
                        !!getUserInfo()?.lastName
                          ? getUserInfo()
                              ?.lastName.split("")[0]
                              .toUpperCase() || ""
                          : ""
                      }`
                    )}
                  </div>
                  <div className="info">
                    <h5 className="name">{userInfo()}</h5>
                    <div className="selectField">
                      <select
                        value={fields.isPublic}
                        onChange={(e) => handleChange("isPublic", e)}
                      >
                        <option value="public">Public</option>
                        <option value="private">Private</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="textBox">
                  <textarea
                    placeholder={
                      getUserInfo()
                        ? "Hey, what's your status, " +
                          getUserInfo().FirstName +
                          " " +
                          getUserInfo().lastName +
                          " ?"
                        : "Jeremy?"
                    }
                    onChange={(e) => handleChange("statusUpdate", e)}
                  ></textarea>
                  {linkStatus == true
                    ? postLinkList.map((item, index) => (
                        <div key={index} className="row mb-2">
                          <div className="col-sm-10">
                            <textarea
                              style={{ marginBottom: 0 }}
                              className="linkText"
                              placeholder="Link Attachment"
                              value={item}
                              onChange={(e) => handleLinkChange(e, index, item)}
                            />
                            <div className="error">{errorsArray[index]}</div>
                          </div>
                          <div className="col-sm-2">
                            {index === postLinkList.length - 1 &&
                            postLinkList[0] !== "" &&
                            postLinkList[1] !== "" &&
                            postLinkList[2] !== "" &&
                            postLinkList[3] !== "" &&
                            postLinkList[4] !== "" ? (
                              <IconButton
                                aria-label="delete"
                                size="medium"
                                onClick={(e) => handleAddLink(e, item)}
                              >
                                <AddIcon fontSize="inherit" />
                              </IconButton>
                            ) : null}
                            {index === postLinkList.length - 1 &&
                            index !== 0 ? (
                              <IconButton
                                aria-label="delete"
                                size="medium"
                                onClick={(e) => handleRemoveLink(e, index)}
                              >
                                <DeleteIcon fontSize="inherit" />
                              </IconButton>
                            ) : null}
                            {index !== postLinkList.length - 1 ? (
                              <IconButton
                                aria-label="delete"
                                size="medium"
                                onClick={(e) => handleRemoveLink(e, index)}
                              >
                                <DeleteIcon fontSize="inherit" />
                              </IconButton>
                            ) : null}
                          </div>
                        </div>
                      ))
                    : null}

                  <div className="imgWrap">
                    {fields.posts &&
                      fields.posts.map((post, index) => (
                        post.typeofpost === 2 ? (
                          <div key={index} className="video-preview" style={{width: '150px', position: 'relative'}}>
                            <video playsInline>
                              <source src={post.postimagevideoandlinkurl} alt="video" />
                            </video>
                            <div className="video-play-button" style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              cursor: 'pointer'
                            }}>
                              <div className="video-play-inner">
                                <button>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14">
                                    <path className="a" d="M8.1,0l8.1,10.8H0Z" transform="translate(8) rotate(90)" />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <img 
                            src={post.postimagevideoandlinkurl}
                            key={index}
                            width="150px"
                            alt="image"
                          />
                        )
                      ))}
                    {(props.savePost.loading || isClickSubmitDis) && selectedFiles.some(file => file.typeofpost === 2) && (
                      <div style={{marginTop: '10px'}}>
                        <h3 style={{fontSize: '14px', color: '#666', marginBottom: '10px'}}>
                          Your video is uploading, and will be processed to ensure maximum device compatibility. You'll receive an email when the video is live!
                        </h3>
                        <InlineLoader open={true} />
                      </div>
                    )}
                  </div>
                  <div className="mapbox mt-2 mb-2">
                    <MapWrapper latlong={latlong} />
                  </div>
                </div>
                <div className="flexBox">
                  <ul>
                    <li>
                      <a className="videoLink">
                        <span className="icon">
                          <label
                            htmlFor="file-input"
                            style={{ marginRight: "10px !important" }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25.755"
                              height="18"
                              viewBox="0 0 25.755 18"
                            >
                              <defs>
                                <style></style>
                              </defs>
                              <path
                                className="a"
                                d="M24.914,4.5H3.841A2.289,2.289,0,0,0,1.5,6.75v13.5A2.3,2.3,0,0,0,3.841,22.5H24.914a2.293,2.293,0,0,0,2.33-2.25l.012-13.5A2.3,2.3,0,0,0,24.914,4.5Zm.5,16.252H3.333V6.242H25.415ZM19.06,13.5,10.865,18V9Z"
                                transform="translate(-1.5 -4.5)"
                              ></path>
                            </svg>
                          </label>
                          <input
                            id="file-input"
                            type="file"
                            accept=".mp3,.mp4"
                            style={{ display: "none" }}
                            onChange={(e) => {
                              handleChange("videos", e);
                              // closeLinkField();
                              e.currentTarget.value = null;
                            }}
                            multiple
                          />
                          <label htmlFor="file-input">
                            <span>Video</span>
                          </label>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        className="attachLink"
                        onClick={() => {
                          openLinkField();
                        }}
                      >
                        <span className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="31"
                            height="17"
                            viewBox="0 0 31 17"
                          >
                            <defs>
                              <style></style>
                            </defs>
                            <g transform="translate(-0.71 -9.5)">
                              <path
                                className="a"
                                d="M18.5,10.5H23a7.5,7.5,0,1,1,0,15H18.5m-5,0H9a7.5,7.5,0,1,1,0-15h4.5"
                                transform="translate(0.21)"
                              ></path>
                              <path
                                className="a"
                                d="M12,18H25"
                                transform="translate(-2.29)"
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <span>Link</span>
                      </a>
                    </li>
                    <li>
                      <a className="photoLink">
                        <span className="icon">
                          <label htmlFor="input">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24.079"
                              height="18"
                              viewBox="0 0 24.079 18"
                            >
                              <defs>
                                <style></style>
                              </defs>
                              <path
                                className="a"
                                d="M25.142,5.142l0,0V20.568l0,0H4.078l0,0V5.144l0,0Zm0-1.286H4.076a1.413,1.413,0,0,0-1.5,1.286V20.57a1.413,1.413,0,0,0,1.5,1.286H25.145a1.413,1.413,0,0,0,1.5-1.286V5.142a1.413,1.413,0,0,0-1.5-1.286Zm-3.01,4.5a2.113,2.113,0,0,1-2.257,1.929A2.113,2.113,0,0,1,17.62,8.356a2.113,2.113,0,0,1,2.257-1.929,2.113,2.113,0,0,1,2.257,1.929Zm1.5,10.929H5.581V16.713L10.848,9l6.02,6.429h1.5L23.64,11.57Z"
                                transform="translate(-2.571 -3.856)"
                              ></path>
                            </svg>
                          </label>
                        </span>
                        <label htmlFor="input">
                          {" "}
                          <span>Photo</span>
                        </label>
                        <input
                          id="input"
                          type="file"
                          accept=".jpg,.jpeg,.png"
                          style={{ display: "none" }}
                          onChange={(e) => {
                            handleChange("photos", e);
                            // closeLinkField();
                            e.currentTarget.value = null;
                          }}
                          multiple
                        />
                      </a>
                    </li>
                    <li>
                      <a className="checkInLink">
                        <span
                          className="icon"
                          onClick={() => {
                            OpenModal();
                          }}
                        >
                          <svg
                            className="pin-icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="15.152"
                            height="21.219"
                            viewBox="0 0 15.152 21.219"
                          >
                            <g
                              id="location"
                              transform="translate(-6.75 -2.248)"
                            >
                              <path
                                id="Path_431"
                                data-name="Path 431"
                                d="M18.781,12.765a1.515,1.515,0,1,1-1.515-1.515A1.515,1.515,0,0,1,18.781,12.765Z"
                                transform="translate(-2.938 -2.938)"
                                fill="#ffffff"
                              ></path>
                              <path
                                id="Path_432"
                                data-name="Path 432"
                                d="M14.326,2.25A7.426,7.426,0,0,0,6.75,9.5c0,1.9.867,4.432,2.577,7.519a55.569,55.569,0,0,0,3.788,5.839,1.5,1.5,0,0,0,2.424,0,55.789,55.789,0,0,0,3.788-5.835C21.036,13.932,21.9,11.4,21.9,9.5A7.426,7.426,0,0,0,14.326,2.25Zm0,10.607a3.031,3.031,0,1,1,3.031-3.031,3.031,3.031,0,0,1-3.031,3.031Z"
                                transform="translate(0)"
                                fill="#c0321f"
                              ></path>
                            </g>
                          </svg>
                        </span>{" "}
                        <span
                          onClick={() => {
                            OpenModal();
                          }}
                        >
                          {" "}
                          Check In{" "}
                        </span>
                        <input
                          id="Check-In"
                          type="Checkin"
                          style={{ display: "none" }}
                          onChange={(e) => {
                            handleChange("CheckIn", e);
                          }}
                          multiple
                        />
                        {/* {showCheckModal && (
                          <CheckInModal
                            setCheckShowModal={setCheckShowModal}
                            setLocation={setLocation}
                          />
                        )} */}
                      </a>
                    </li>
                  </ul>
                  <button className="postBtn" disabled={isClickSubmitDis} onClick={() => handleSubmit()}>
                    Post Status
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog open={showSharePostModal}
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  onSharePopClose()
                }
              }}
              className="matDialogCustom"
      >
        <SharePost handleModal={()=>onSharePopClose()} pageType={"Home"}  modalData={modalData} />
      </Dialog>
      <Dialog open={showCheckModal} className="checkInModalOverlay matDialogCustom"
              onClose={(event, reason)=> {
                if (reason == "backdropClick" || reason == "escapeKeyDown") {
                  setCheckShowModal(false)
                }
              }}
      >
        <CheckInModal
          setCheckShowModal={setCheckShowModal}
          setLocation={setLocation}
          locationName={locationName}
        />
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    savePost: state.athlete.dashboard.home.savePost,
    athleteData: state.common.athletesData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    savePostStart: (data) => dispatch(savePostStart(data)),
    savePostSuccess: (data) => dispatch(savePostSuccess(data)),
    saveVideoPhotoStart: (data) => dispatch(saveVideoPhotoStart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostUpdate);
