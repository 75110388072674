import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

// Styled Components
const FormContainer = styled.form`
  max-width: 800px;
  margin: 0 auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
`;

const FormTitle = styled.h2`
  color: #0046fb;
  text-align: center;
  margin-bottom: 30px;
  font-weight: 700;
`;

const FormList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const FormItem = styled.li`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const Label = styled.label`
  font-weight: 600;
  margin-bottom: 8px;
  color: #333;
  
  @media (min-width: 768px) {
    width: 40%;
    padding-right: 15px;
    margin-bottom: 0;
    padding-top: 8px;
  }
`;

const Input = styled.input`
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  transition: border 0.3s ease;
  width: 100%;
  
  &:focus {
    outline: none;
    border-color: #0046fb;
    box-shadow: 0 0 0 2px rgba(0, 70, 251, 0.2);
  }
  
  @media (min-width: 768px) {
    width: 60%;
  }
`;

const Select = styled.select`
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
  cursor: pointer;
  width: 100%;
  
  &:focus {
    outline: none;
    border-color: #0046fb;
    box-shadow: 0 0 0 2px rgba(0, 70, 251, 0.2);
  }
  
  @media (min-width: 768px) {
    width: 60%;
  }
`;

const TextArea = styled.textarea`
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  min-height: 100px;
  width: 100%;
  
  &:focus {
    outline: none;
    border-color: #0046fb;
    box-shadow: 0 0 0 2px rgba(0, 70, 251, 0.2);
  }
  
  @media (min-width: 768px) {
    width: 60%;
  }
`;

const RequiredMark = styled.span`
  color: #e53935;
  margin-left: 4px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  
  @media (min-width: 768px) {
    width: 60%;
  }
`;

const CheckboxGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  margin-top: 5px;
  
  @media (min-width: 576px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const CheckboxItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const CheckboxLabel = styled.span`
  margin-left: 8px;
  cursor: pointer;
`;

const Checkbox = styled.input`
  width: 18px;
  height: 18px;
  cursor: pointer;
  accent-color: #0046fb;
`;

const SubmitButton = styled.button`
  background-color: #0046fb;
  color: white;
  font-weight: bold;
  font-size: 18px;
  border: none;
  border-radius: 4px;
  padding: 12px 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 20px auto;
  display: block;
  
  &:hover {
    background-color: #003ad7;
  }
`;

const RequiredInfo = styled.div`
  font-size: 14px;
  color: #666;
  text-align: center;
  margin-top: 10px;
`;

const FormSection = styled.div`
  margin: 30px 0;
  border-top: 1px solid #eee;
  padding-top: 20px;
  
  &:first-child {
    margin-top: 0;
    border-top: none;
    padding-top: 0;
  }
`;

const SectionTitle = styled.h3`
  color: #0046fb;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 18px;
`;

// SignupForm Component
function SignupForm() {
  const [formValues, setFormValues] = useState({
    Email: '',
    Firstname: '',
    Lastname: '',
    Phone: '',
    City: '',
    State: '',
    User187: '',
    User188: '',
    User189: '',
    User190: '',
    User192: '',
    User193: '',
    User194: '',
    User195: '',
    User196: '',
    User197: '',
    User198: '',
    User199: '',
    User200: '',
    User202: '',
    User204: '',
    User206: '',
    User207: '',
    User208: '',
    User209: '',
    User214: '',
  });

  // State for checkboxes
  const [checkboxes, setCheckboxes] = useState({
    User191: [],
    User201: [],
    User203: [],
    User205: [],
  });

  // Reference to the email input
  const emailInputRef = useRef(null);

  // Check for autofill
  useEffect(() => {
    // This detects when browser autofill happens and updates the form state
    const checkAutofill = () => {
      if (emailInputRef.current) {
        const emailValue = emailInputRef.current.value;
        if (emailValue !== formValues.Email) {
          setFormValues(prev => ({
            ...prev,
            Email: emailValue
          }));
        }
      }
    };

    // Run initial check in case autofill happened before component mounted
    checkAutofill();
    
    // Add an interval to check periodically for autofill changes
    const intervalId = setInterval(checkAutofill, 1000);
    
    // Clean up interval on unmount
    return () => clearInterval(intervalId);
  }, [formValues.Email]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Special handler for email to handle email-specific validation
  const handleEmailChange = (e) => {
    let value = e.target.value;
    
    // Remove invalid characters (optional - you may want to keep this behavior)
    value = value.replace(/[,;:!#%^&*()/<>\s]/g, '');
    
    setFormValues(prev => ({
      ...prev,
      Email: value
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    
    setCheckboxes(prev => {
      if (checked) {
        return {
          ...prev,
          [name]: [...prev[name], value]
        };
      } else {
        return {
          ...prev,
          [name]: prev[name].filter(item => item !== value)
        };
      }
    });
  };

  const checkMandatory = (e) => {
    // Force-sync the email field one more time before validation
    if (emailInputRef.current) {
      const emailValue = emailInputRef.current.value;
      if (emailValue !== formValues.Email) {
        setFormValues(prev => ({
          ...prev,
          Email: emailValue
        }));
        
        // Since we just updated state which won't be available immediately,
        // let's use the actual input value for validation
        formValues.Email = emailValue;
      }
    }
    
    let ok2submit = 1;
    let errorFields = '';
    const atTest = /\@/;
    const dotTest = /\./;
    
    // Email validation
    if (!atTest.test(formValues.Email) || !dotTest.test(formValues.Email)) {
      ok2submit = 0;
      errorFields += 'Email, ';
    }
    
    const numAts = (formValues.Email.match(/@/g) || []).length;
    if (numAts > 1) {
      ok2submit = 0;
      errorFields += 'Email (invalid or multiple email addresses), ';
    }
    
    // Required field validation
    if (!formValues.Email || formValues.Email === ' ') {
      ok2submit = 0;
      errorFields += 'Email, ';
    }
    
    if (!formValues.Firstname || formValues.Firstname === ' ') {
      ok2submit = 0;
      errorFields += 'First Name, ';
    }
    
    if (!formValues.Lastname || formValues.Lastname === ' ') {
      ok2submit = 0;
      errorFields += 'Last Name, ';
    }
    
    if (!formValues.Phone || formValues.Phone === ' ') {
      ok2submit = 0;
      errorFields += 'Phone, ';
    }
    
    if (!formValues.User187 || formValues.User187 === ' ') {
      ok2submit = 0;
      errorFields += 'Did you play college baseball?, ';
    }
    
    if (!formValues.User188 || formValues.User188 === ' ') {
      ok2submit = 0;
      errorFields += 'What is highest level of college you played?, ';
    }
    
    if (!formValues.User189 || formValues.User189 === ' ') {
      ok2submit = 0;
      errorFields += 'Did you play professionally?, ';
    }
    
    // Checkbox validations
    if (checkboxes.User191.length === 0) {
      ok2submit = 0;
      errorFields += 'What levels have you coached or scouted?, ';
    }
    
    if (!formValues.User195 || formValues.User195 === ' ') {
      ok2submit = 0;
      errorFields += 'Do you have experience recruiting players as a college coach or experience recruiting for a sports team? (share details), ';
    }
    
    if (!formValues.User196 || formValues.User196 === ' ') {
      ok2submit = 0;
      errorFields += 'Why do you want to work in the college recruiting / sports industry?, ';
    }
    
    if (!formValues.User199 || formValues.User199 === ' ') {
      ok2submit = 0;
      errorFields += 'How much time per week would you be able to commit to SportsForce?, ';
    }
    
    if (!formValues.User200 || formValues.User200 === ' ') {
      ok2submit = 0;
      errorFields += 'Are you seriously looking for remote work opportunities?, ';
    }
    
    if (checkboxes.User201.length === 0) {
      ok2submit = 0;
      errorFields += 'What technology are you proficient in?, ';
    }
    
    if (!formValues.User202 || formValues.User202 === ' ') {
      ok2submit = 0;
      errorFields += 'Are you comfortable using Social Media?, ';
    }
    
    if (checkboxes.User203.length === 0) {
      ok2submit = 0;
      errorFields += 'Do you have any professional sales experience?, ';
    }
    
    if (!formValues.User204 || formValues.User204 === ' ') {
      ok2submit = 0;
      errorFields += 'Have you done any virtual presentations with Zoom, WebX, GoToMeeting, etc., ';
    }
    
    if (checkboxes.User205.length === 0) {
      ok2submit = 0;
      errorFields += 'Have you worked for yourself in the past?, ';
    }
    
    if (!formValues.User206 || formValues.User206 === ' ') {
      ok2submit = 0;
      errorFields += 'If yes please share some quick details, ';
    }
    
    if (!formValues.User207 || formValues.User207 === ' ') {
      ok2submit = 0;
      errorFields += 'In previous jobs have you had to ask someone for payment information?, ';
    }
    
    if (!formValues.User208 || formValues.User208 === ' ') {
      ok2submit = 0;
      errorFields += 'Do you have any current or upcoming time commitment issues?, ';
    }
    
    if (!formValues.User209 || formValues.User209 === ' ') {
      ok2submit = 0;
      errorFields += 'Do you have any additional comments, questions or concerns?, ';
    }
    
    if (!formValues.City || formValues.City === ' ') {
      ok2submit = 0;
      errorFields += 'City, ';
    }
    
    if (!formValues.State || formValues.State === ' ') {
      ok2submit = 0;
      errorFields += 'State, ';
    }
    
    if (ok2submit === 0) {
      const errorFields2 = errorFields.replace(/\, $/, '');
      alert('To submit this form, please ensure you have entered information for: ' + errorFields2);
      e.preventDefault();
      return false;
    } else {
      return true;
    }
  };

  return (
    <FormContainer
      name="signupForm"
      id="signupForm"
      method="post"
      action="https://action.sportsforceonline.com/j.pl?0f5445da6977e87998e8f968b4eaa0173c1221438b1440478ef6c2e4848440b0"
      onSubmit={checkMandatory}
    >
      <FormTitle>Baseball Coaching Position Application</FormTitle>
      
      <FormList>
        {/* Personal Information Section */}
        <FormSection>
          <SectionTitle>Personal Information</SectionTitle>
          
          <FormItem id="field_Email">
            <Label htmlFor="signup_Email">
              Email<RequiredMark>*</RequiredMark>
            </Label>
            <Input
              type="email"
              name="Email"
              maxLength="64"
              id="signup_Email"
              ref={emailInputRef}
              value={formValues.Email}
              onChange={handleEmailChange}
              style={{ textTransform: 'lowercase' }}
              onBlur={() => {
                if (emailInputRef.current) {
                  setFormValues(prev => ({
                    ...prev,
                    Email: emailInputRef.current.value
                  }));
                }
              }}
            />
          </FormItem>
          
          <FormItem id="field_Firstname">
            <Label htmlFor="signup_Firstname">
              First Name<RequiredMark>*</RequiredMark>
            </Label>
            <Input
              type="text"
              name="Firstname"
              id="signup_Firstname"
              maxLength="64"
              value={formValues.Firstname}
              onChange={handleChange}
            />
          </FormItem>
          
          <FormItem id="field_Lastname">
            <Label htmlFor="signup_Lastname">
              Last Name<RequiredMark>*</RequiredMark>
            </Label>
            <Input
              type="text"
              name="Lastname"
              id="signup_Lastname"
              maxLength="64"
              value={formValues.Lastname}
              onChange={handleChange}
            />
          </FormItem>
          
          <FormItem id="field_Phone">
            <Label htmlFor="signup_Phone">
              Phone<RequiredMark>*</RequiredMark>
            </Label>
            <Input
              type="tel"
              name="Phone"
              id="signup_Phone"
              maxLength="64"
              value={formValues.Phone}
              onChange={handleChange}
              placeholder="(123) 456-7890"
            />
          </FormItem>
          
          <FormItem id="field_City">
            <Label htmlFor="signup_City">
              City<RequiredMark>*</RequiredMark>
            </Label>
            <Input
              type="text"
              name="City"
              id="signup_City"
              maxLength="64"
              value={formValues.City}
              onChange={handleChange}
            />
          </FormItem>
          
          <FormItem id="field_State">
            <Label htmlFor="signup_State">
              State<RequiredMark>*</RequiredMark>
            </Label>
            <Input
              type="text"
              name="State"
              id="signup_State"
              maxLength="64"
              value={formValues.State}
              onChange={handleChange}
            />
          </FormItem>
        </FormSection>
        
        {/* Playing Experience Section */}
        <FormSection>
          <SectionTitle>Baseball Playing Experience</SectionTitle>
          
          <FormItem id="field_User187">
            <Label htmlFor="signup_User187">
              Did you play college baseball?<RequiredMark>*</RequiredMark>
            </Label>
            <Select 
              name="User187" 
              id="signup_User187"
              value={formValues.User187}
              onChange={handleChange}
            >
              <option value="">Choose</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
          </FormItem>
          
          <FormItem id="field_User188">
            <Label htmlFor="signup_User188">
              What is highest level of college you played?<RequiredMark>*</RequiredMark>
            </Label>
            <Select 
              name="User188" 
              id="signup_User188"
              value={formValues.User188}
              onChange={handleChange}
            >
              <option value="">Choose</option>
              <option value="NCAA DI">NCAA DI</option>
              <option value="NCAA DII">NCAA DII</option>
              <option value="NCAA DIII">NCAA DIII</option>
              <option value="NAIA">NAIA</option>
              <option value="Junior College">Junior College</option>
              <option value="Not Applicable">Not Applicable</option>
            </Select>
          </FormItem>
          
          <FormItem id="field_User189">
            <Label htmlFor="signup_User189">
              Did you play professionally?<RequiredMark>*</RequiredMark>
            </Label>
            <Select 
              name="User189" 
              id="signup_User189"
              value={formValues.User189}
              onChange={handleChange}
            >
              <option value="">Choose</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
          </FormItem>
        </FormSection>
        
        {/* Coaching Experience Section */}
        <FormSection>
          <SectionTitle>Coaching Experience</SectionTitle>
          
          <FormItem id="field_User190">
            <Label htmlFor="signup_User190">
              Have you coached in college?
            </Label>
            <Select 
              name="User190" 
              id="signup_User190"
              value={formValues.User190}
              onChange={handleChange}
            >
              <option value="">Choose</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
          </FormItem>
          
          <FormItem id="field_User191">
            <Label htmlFor="signup_User191">
              What levels have you coached or scouted?<RequiredMark>*</RequiredMark>
            </Label>
            <CheckboxContainer>
              <CheckboxGroup>
                <CheckboxItem>
                  <Checkbox 
                    type="checkbox" 
                    name="User191" 
                    value="Professional" 
                    checked={checkboxes.User191.includes('Professional')}
                    onChange={handleCheckboxChange}
                  />
                  <CheckboxLabel>Professional</CheckboxLabel>
                </CheckboxItem>
                <CheckboxItem>
                  <Checkbox 
                    type="checkbox" 
                    name="User191" 
                    value="College" 
                    checked={checkboxes.User191.includes('College')}
                    onChange={handleCheckboxChange}
                  />
                  <CheckboxLabel>College</CheckboxLabel>
                </CheckboxItem>
                <CheckboxItem>
                  <Checkbox 
                    type="checkbox" 
                    name="User191" 
                    value="High School" 
                    checked={checkboxes.User191.includes('High School')}
                    onChange={handleCheckboxChange}
                  />
                  <CheckboxLabel>High School</CheckboxLabel>
                </CheckboxItem>
                <CheckboxItem>
                  <Checkbox 
                    type="checkbox" 
                    name="User191" 
                    value="Travel Ball" 
                    checked={checkboxes.User191.includes('Travel Ball')}
                    onChange={handleCheckboxChange}
                  />
                  <CheckboxLabel>Travel Ball</CheckboxLabel>
                </CheckboxItem>
                <CheckboxItem>
                  <Checkbox 
                    type="checkbox" 
                    name="User191" 
                    value="Private Instruction" 
                    checked={checkboxes.User191.includes('Private Instruction')}
                    onChange={handleCheckboxChange}
                  />
                  <CheckboxLabel>Private Instruction</CheckboxLabel>
                </CheckboxItem>
                <CheckboxItem>
                  <Checkbox 
                    type="checkbox" 
                    name="User191" 
                    value="Youth" 
                    checked={checkboxes.User191.includes('Youth')}
                    onChange={handleCheckboxChange}
                  />
                  <CheckboxLabel>Youth</CheckboxLabel>
                </CheckboxItem>
              </CheckboxGroup>
            </CheckboxContainer>
          </FormItem>
          
          <FormItem id="field_User192">
            <Label htmlFor="signup_User192">
              If yes, please describe your coaching / scouting experience?
            </Label>
            <TextArea
              name="User192"
              id="signup_User192"
              rows="3"
              value={formValues.User192}
              onChange={handleChange}
              placeholder="Please provide details about your coaching or scouting experience"
            />
          </FormItem>
          
          <FormItem id="field_User193">
            <Label htmlFor="signup_User193">
              Are you currently coaching for a college, high school, travel team, facility or 
              private instruction or scouting for a professional team/league?
            </Label>
            <TextArea
              name="User193"
              id="signup_User193"
              rows="3"
              value={formValues.User193}
              onChange={handleChange}
              placeholder="Share details about your current coaching position if applicable"
            />
          </FormItem>
        </FormSection>
        
        {/* Recruiting Experience Section */}
        <FormSection>
          <SectionTitle>Recruiting Experience</SectionTitle>
          
          <FormItem id="field_User194">
            <Label htmlFor="signup_User194">
              What level of knowledge do you have about the college recruiting process?
            </Label>
            <Select 
              name="User194" 
              id="signup_User194"
              value={formValues.User194}
              onChange={handleChange}
            >
              <option value="">Choose</option>
              <option value="Expert">Expert</option>
              <option value="Intermediate">Intermediate</option>
              <option value="Novice">Novice</option>
              <option value="No experience">No experience</option>
            </Select>
          </FormItem>
          
          <FormItem id="field_User195">
            <Label htmlFor="signup_User195">
              Do you have experience recruiting players as a college coach or for a sports team?
              <RequiredMark>*</RequiredMark>
            </Label>
            <TextArea
              name="User195"
              id="signup_User195"
              rows="3"
              value={formValues.User195}
              onChange={handleChange}
              placeholder="Share details about your recruiting experience"
            />
          </FormItem>
          
          <FormItem id="field_User196">
            <Label htmlFor="signup_User196">
              Why do you want to work in the college recruiting / sports industry?
              <RequiredMark>*</RequiredMark>
            </Label>
            <TextArea
              name="User196"
              id="signup_User196"
              rows="3"
              value={formValues.User196}
              onChange={handleChange}
              placeholder="Please explain your interest in this field"
            />
          </FormItem>
        </FormSection>
        
        {/* Income & Availability Section */}
        <FormSection>
          <SectionTitle>Income & Availability</SectionTitle>
          
          <FormItem id="field_User197">
            <Label htmlFor="signup_User197">
              What type of income opportunity are you looking for?
            </Label>
            <Select 
              name="User197" 
              id="signup_User197"
              value={formValues.User197}
              onChange={handleChange}
            >
              <option value="">Choose</option>
              <option value="Full time">Full time</option>
              <option value="Part time">Part time</option>
              <option value="Not sure">Not sure</option>
            </Select>
          </FormItem>
          
          <FormItem id="field_User198">
            <Label htmlFor="signup_User198">
              What would you like your monthly income to be from Sportsforce?
            </Label>
            <Select 
              name="User198" 
              id="signup_User198"
              value={formValues.User198}
              onChange={handleChange}
            >
              <option value="">Choose</option>
              <option value="$2000 - $3000">$2000 - $3000</option>
              <option value="$3000 - $5000">$3000 - $5000</option>
              <option value="$5000+">$5000+</option>
            </Select>
          </FormItem>
          
          <FormItem id="field_User199">
            <Label htmlFor="signup_User199">
              How much time per week would you be able to commit?
              <RequiredMark>*</RequiredMark>
            </Label>
            <Select 
              name="User199" 
              id="signup_User199"
              value={formValues.User199}
              onChange={handleChange}
            >
              <option value="">Choose</option>
              <option value="10 - 15 hours">10 - 15 hours</option>
              <option value="15 - 20 hours">15 - 20 hours</option>
              <option value="20 - 30 hours">20 - 30 hours</option>
              <option value="40+ hours">40+ hours</option>
            </Select>
          </FormItem>
          
          <FormItem id="field_User200">
            <Label htmlFor="signup_User200">
              Are you seriously looking for remote work opportunities?
              <RequiredMark>*</RequiredMark>
            </Label>
            <Select 
              name="User200" 
              id="signup_User200"
              value={formValues.User200}
              onChange={handleChange}
            >
              <option value="">Choose</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
          </FormItem>
        </FormSection>
        
        {/* Technology & Skills Section */}
        <FormSection>
          <SectionTitle>Technology & Skills</SectionTitle>
          
          <FormItem id="field_User201">
            <Label htmlFor="signup_User201">
              What technology are you proficient in?
              <RequiredMark>*</RequiredMark>
            </Label>
            <CheckboxContainer>
              <CheckboxGroup>
                <CheckboxItem>
                  <Checkbox 
                    type="checkbox" 
                    name="User201" 
                    value="Email" 
                    checked={checkboxes.User201.includes('Email')}
                    onChange={handleCheckboxChange}
                  />
                  <CheckboxLabel>Email</CheckboxLabel>
                </CheckboxItem>
                <CheckboxItem>
                  <Checkbox 
                    type="checkbox" 
                    name="User201" 
                    value="MSFT Office (excel word)" 
                    checked={checkboxes.User201.includes('MSFT Office (excel word)')}
                    onChange={handleCheckboxChange}
                  />
                  <CheckboxLabel>MSFT Office (excel, word)</CheckboxLabel>
                </CheckboxItem>
                <CheckboxItem>
                  <Checkbox 
                    type="checkbox" 
                    name="User201" 
                    value="Google suite (sheets docs)" 
                    checked={checkboxes.User201.includes('Google suite (sheets docs)')}
                    onChange={handleCheckboxChange}
                  />
                  <CheckboxLabel>Google suite (sheets, docs)</CheckboxLabel>
                </CheckboxItem>
                <CheckboxItem>
                  <Checkbox 
                    type="checkbox" 
                    name="User201" 
                    value="Powerpoint / Google suite (slides)" 
                    checked={checkboxes.User201.includes('Powerpoint / Google suite (slides)')}
                    onChange={handleCheckboxChange}
                  />
                  <CheckboxLabel>PowerPoint / Google Slides</CheckboxLabel>
                </CheckboxItem>
                <CheckboxItem>
                  <Checkbox 
                    type="checkbox" 
                    name="User201" 
                    value="CRM software (ex. SalesForce HubSpot etc)" 
                    checked={checkboxes.User201.includes('CRM software (ex. SalesForce HubSpot etc)')}
                    onChange={handleCheckboxChange}
                  />
                  <CheckboxLabel>CRM software (SalesForce, HubSpot, etc)</CheckboxLabel>
                </CheckboxItem>
              </CheckboxGroup>
            </CheckboxContainer>
          </FormItem>
          
          <FormItem id="field_User202">
            <Label htmlFor="signup_User202">
              Are you comfortable using Social Media?
              <RequiredMark>*</RequiredMark>
            </Label>
            <Select 
              name="User202" 
              id="signup_User202"
              value={formValues.User202}
              onChange={handleChange}
            >
              <option value="">Choose</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
          </FormItem>
        </FormSection>
        
        {/* Sales Experience Section */}
        <FormSection>
          <SectionTitle>Sales Experience</SectionTitle>
          
          <FormItem id="field_User203">
            <Label htmlFor="signup_User203">
              Do you have any professional sales experience?
              <RequiredMark>*</RequiredMark>
            </Label>
            <CheckboxContainer>
              <CheckboxGroup>
                <CheckboxItem>
                  <Checkbox 
                    type="checkbox" 
                    name="User203" 
                    value="No sales experience" 
                    checked={checkboxes.User203.includes('No sales experience')}
                    onChange={handleCheckboxChange}
                  />
                  <CheckboxLabel>No sales experience</CheckboxLabel>
                </CheckboxItem>
                <CheckboxItem>
                  <Checkbox 
                    type="checkbox" 
                    name="User203" 
                    value="Inside / Field Sales" 
                    checked={checkboxes.User203.includes('Inside / Field Sales')}
                    onChange={handleCheckboxChange}
                  />
                  <CheckboxLabel>Inside / Field Sales</CheckboxLabel>
                </CheckboxItem>
                <CheckboxItem>
                  <Checkbox 
                    type="checkbox" 
                    name="User203" 
                    value="Field Sales (face to face)" 
                    checked={checkboxes.User203.includes('Field Sales (face to face)')}
                    onChange={handleCheckboxChange}
                  />
                  <CheckboxLabel>Field Sales (face to face)</CheckboxLabel>
                </CheckboxItem>
                <CheckboxItem>
                  <Checkbox 
                    type="checkbox" 
                    name="User203" 
                    value="Phone Sales (outbound & inbound)" 
                    checked={checkboxes.User203.includes('Phone Sales (outbound & inbound)')}
                    onChange={handleCheckboxChange}
                  />
                  <CheckboxLabel>Phone Sales (outbound & inbound)</CheckboxLabel>
                </CheckboxItem>
                <CheckboxItem>
                  <Checkbox 
                    type="checkbox" 
                    name="User203" 
                    value="Client account / customer support" 
                    checked={checkboxes.User203.includes('Client account / customer support')}
                    onChange={handleCheckboxChange}
                  />
                  <CheckboxLabel>Client account / customer support</CheckboxLabel>
                </CheckboxItem>
                <CheckboxItem>
                  <Checkbox 
                    type="checkbox" 
                    name="User203" 
                    value="Group presentation sales (ex. timeshare)" 
                    checked={checkboxes.User203.includes('Group presentation sales (ex. timeshare)')}
                    onChange={handleCheckboxChange}
                  />
                  <CheckboxLabel>Group presentation sales</CheckboxLabel>
                </CheckboxItem>
                <CheckboxItem>
                  <Checkbox 
                    type="checkbox" 
                    name="User203" 
                    value="B2B sales" 
                    checked={checkboxes.User203.includes('B2B sales')}
                    onChange={handleCheckboxChange}
                  />
                  <CheckboxLabel>B2B sales</CheckboxLabel>
                </CheckboxItem>
              </CheckboxGroup>
            </CheckboxContainer>
          </FormItem>
          
          <FormItem id="field_User204">
            <Label htmlFor="signup_User204">
              Have you done any virtual presentations with Zoom, WebEx, etc.?
              <RequiredMark>*</RequiredMark>
            </Label>
            <Select 
              name="User204" 
              id="signup_User204"
              value={formValues.User204}
              onChange={handleChange}
            >
              <option value="">Choose</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
          </FormItem>
          
          <FormItem id="field_User214">
            <Label htmlFor="signup_User214">
              Have you done any group college recruiting talks in person or online?
            </Label>
            <Select 
              name="User214" 
              id="signup_User214"
              value={formValues.User214}
              onChange={handleChange}
            >
              <option value="">Choose</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
          </FormItem>
          
          <FormItem id="field_User205">
            <Label htmlFor="signup_User205">
              Have you worked for yourself in the past?
              <RequiredMark>*</RequiredMark>
            </Label>
            <CheckboxContainer>
              <CheckboxGroup>
                <CheckboxItem>
                  <Checkbox 
                    type="checkbox" 
                    name="User205" 
                    value="Contractor (1099)" 
                    checked={checkboxes.User205.includes('Contractor (1099)')}
                    onChange={handleCheckboxChange}
                  />
                  <CheckboxLabel>Contractor (1099)</CheckboxLabel>
                </CheckboxItem>
                <CheckboxItem>
                  <Checkbox 
                    type="checkbox" 
                    name="User205" 
                    value="Owned a business" 
                    checked={checkboxes.User205.includes('Owned a business')}
                    onChange={handleCheckboxChange}
                  />
                  <CheckboxLabel>Owned a business</CheckboxLabel>
                </CheckboxItem>
                <CheckboxItem>
                  <Checkbox 
                    type="checkbox" 
                    name="User205" 
                    value="Independent sales" 
                    checked={checkboxes.User205.includes('Independent sales')}
                    onChange={handleCheckboxChange}
                  />
                  <CheckboxLabel>Independent sales</CheckboxLabel>
                </CheckboxItem>
                <CheckboxItem>
                  <Checkbox 
                    type="checkbox" 
                    name="User205" 
                    value="Commision only" 
                    checked={checkboxes.User205.includes('Commision only')}
                    onChange={handleCheckboxChange}
                  />
                  <CheckboxLabel>Commission only</CheckboxLabel>
                </CheckboxItem>
                <CheckboxItem>
                  <Checkbox 
                    type="checkbox" 
                    name="User205" 
                    value="No I havent" 
                    checked={checkboxes.User205.includes('No I havent')}
                    onChange={handleCheckboxChange}
                  />
                  <CheckboxLabel>No I haven't</CheckboxLabel>
                </CheckboxItem>
              </CheckboxGroup>
            </CheckboxContainer>
          </FormItem>
          
          <FormItem id="field_User206">
            <Label htmlFor="signup_User206">
              If yes please share some quick details
              <RequiredMark>*</RequiredMark>
            </Label>
            <TextArea
              name="User206"
              id="signup_User206"
              rows="3"
              value={formValues.User206}
              onChange={handleChange}
              placeholder="Please provide details about your self-employment experience"
            />
          </FormItem>
          
          <FormItem id="field_User207">
            <Label htmlFor="signup_User207">
              In previous jobs have you had to ask someone for payment information?
              <RequiredMark>*</RequiredMark>
            </Label>
            <Select 
              name="User207" 
              id="signup_User207"
              value={formValues.User207}
              onChange={handleChange}
            >
              <option value="">Choose</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
          </FormItem>
        </FormSection>
        
        {/* Additional Information Section */}
        <FormSection>
          <SectionTitle>Additional Information</SectionTitle>
          
          <FormItem id="field_User208">
            <Label htmlFor="signup_User208">
              Do you have any current or upcoming time commitment issues or concerns?
              <RequiredMark>*</RequiredMark>
            </Label>
            <TextArea
              name="User208"
              id="signup_User208"
              rows="3"
              value={formValues.User208}
              onChange={handleChange}
              placeholder="Please share any scheduling concerns you might have"
            />
          </FormItem>
          
          <FormItem id="field_User209">
            <Label htmlFor="signup_User209">
              Do you have any additional comments, questions or concerns about the potential role?
              <RequiredMark>*</RequiredMark>
            </Label>
            <TextArea
              name="User209"
              id="signup_User209"
              rows="3"
              value={formValues.User209}
              onChange={handleChange}
              placeholder="Share any other information you'd like us to know"
            />
          </FormItem>
        </FormSection>
        
        {/* Hidden fields */}
        <input type="hidden" name="displayPhone" value="" id="fullPhone" />
        <input type="hidden" name="Referrer" value="" />
        <input
          type="hidden"
          name="qstring"
          value="0f5445da6977e87998e8f968b4eaa0173c1221438b1440478ef6c2e4848440b0"
        />
        <input type="hidden" name="formSubmitCheck" value="1" />
        <input type="hidden" name="talon" value='{" version ": 5, " status ": -1}' id="talon" />
        
        {/* Submit Button */}
        <SubmitButton type="submit">Submit Application</SubmitButton>
        
        <RequiredInfo>* Required information</RequiredInfo>
      </FormList>
    </FormContainer>
  );
}

export default SignupForm;
